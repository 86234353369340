<section id="goaie-hackathon">
  <div *ngIf="camps[0]" class="container">
    <div class="text-center">
      <div class="title bg-pattern d-inline-block bg-orange mb-3 mt-5">
        المعسكرات
      </div>
      <div class="subtitle text-center mx-auto mt-2">
        أنشطة ينظمها المكتب للمساهمة في تطوير الذكاء الاصطناعي ونشر ثقافته
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <img
          [src]="camps[0].image | imageLink"
          class="img-fluid rounded-3 cursor-pointer"
          [alt]="camps[0].image.title"
          style="height: 300px"
          routerLink="camps/{{ camps[0].id }}"
        />
      </div>
      <div class="col">
        <div
          class="h-100 d-flex flex-column justify-content-center align-items-start"
        >
          <h5 class="text-primary mt-2">
            {{ camps[0].title }}
          </h5>
          <p class="my-3">
            من تاريخ {{ camps[0].startDate | date }} إلى تاريخ
            {{ camps[0].endDate | date }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
