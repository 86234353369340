<app-page>
  <page-header></page-header>
  <page-content>
    <div id="page-articles-single" class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <img
              [src]="studentProject.image | imageLink"
              class="img-fluid rounded-4 d-block mb-4"
              alt=""
            />
            <h6 class="fw-bold mb-0">مقدم المشروع:</h6>
            <p class="text-muted">
              {{ studentProject.presenter }}
            </p>
          </div>
          <div class="col">
            <h5 class="fw-bold mb-4">
              {{ studentProject.title }}
            </h5>
            <p [innerHTML]="studentProject.description"></p>

            <div class="text-end">
              <a
                [href]="studentProject.document | fileLink"
                target="_blank"
                class="btn btn-primary mx-2 ms-auto"
              >
                <i class="fa-solid fa-arrow-right-to-bracket fa-rotate-90"></i>
              </a>
            </div>
          </div>
        </div>
        <hr class="mb-5" />
        <h5 class="fw-bold text-center my-4">أحدث المشاريع</h5>

        <div class="row">
          <div
            *ngFor="let studentProjec of studentProjects"
            class="col-lg-3 col-md-4 col-sm-6"
          >
            <a routerLink="/students-projects/{{ studentProjec.id }}">
              <div class="article-card px-3 mb-4 mb-md-5">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 275px"
                >
                  <img
                    class="rounded-4 d-block img-fluid"
                    [src]="studentProjec.image | imageLink"
                    [alt]="studentProjec.image.title"
                  />
                </div>
                <h6 class="text-center text-dark fw-bold mt-3">
                  {{ studentProjec.title }}
                </h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
