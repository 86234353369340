import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Application } from "src/app/interfaces/landing-page";
import { SingleApplicationPageData } from "src/app/interfaces/single-application-page";
import { environment } from "src/environments/environment";
import { AppImageZoomComponent } from "../../shared/app-image-zoom/app-image-zoom.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-apps-single-page",
  templateUrl: "./apps-single-page.component.html",
  styleUrls: ["./apps-single-page.component.scss"],
})
export class AppsSinglePageComponent implements OnInit {
  applications!: Application[];
  application!: Application;
  assetsURI = environment.assetsURI;

  constructor(private route: ActivatedRoute,  public dialog: MatDialog) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const { meta, ...page } = this.route.snapshot.data[
        "page"
      ] as SingleApplicationPageData;
      this.application = page.data.application;
    });
  }
  openZoomDialog(imageId: string, alt: string): void {
    const dialogRef = this.dialog.open(AppImageZoomComponent, {
      data: { imageId: imageId, alt: alt },
    });
  }
}
