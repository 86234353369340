<section id="goaie-tools">
  <div class="container">
    <div class="text-center">
      <div class="title bg-pattern d-inline-block bg-orange mb-3 mt-5">
        أدوات وتطبيقات الذكاء الاصطناعي
      </div>
      <div class="subtitle text-center mx-auto mt-2">
        مجموعة من الأدوات والتطبيقات المميزة في مجال الذكاء الاصطناعي
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div
          class="bg-pattern fw-bold px-4 fs-5 d-inline-block bg-green mb-3 mt-5"
        >
          أدوات
        </div>

        <a *ngFor="let tool of tools" class="my-3 d-block">
          <div class="tools-card d-flex flex-row gap-3 align-items-center">
            <div
              class="img-wrapper bg-light-gray bg-opacity-10 rounded-3 overflow-hidden flex-shrink-0 p-3"
            >
              <img
                [src]="tool.image | imageLink"
                class="w-100 h-100 contain cursor-pointer filter-dark img-fluid"
                [alt]="tool.image.title"
                routerLink="/tools/{{ tool.id }}"
              />
            </div>
            <div routerLink="/tools/{{ tool.id }}" class="cursor-pointer">
              <h5 class="fw-bold mb-0 text-dark">{{ tool.name }}</h5>
              <p class="text-muted small mb-0">
                {{ tool.developerCompanyName }}
              </p>
            </div>
          </div>
        </a>

        <a
          routerLink="/tools"
          class="btn btn-light py-2 w-100 text-center fw-bold fs-6"
          >المزيد من الأدوات</a
        >
      </div>

      <div class="col-lg-6">
        <div
          class="bg-pattern fw-bold px-4 fs-5 d-inline-block bg-orange mb-3 mt-5"
        >
          تطبيقات
        </div>

        <a *ngFor="let application of applications" class="my-3 d-block">
          <div class="tools-card d-flex flex-row gap-3 align-items-center">
            <div
              class="img-wrapper bg-light-gray bg-opacity-10 rounded-3 overflow-hidden flex-shrink-0 p-3"
            >
              <img
                [src]="application.image | imageLink"
                class="w-100 h-100 contain filter-dark img-fluid cursor-pointer"
                [alt]="application.image.title"
                routerLink="/applications/{{ application.id }}"
              />
            </div>
            <div
              routerLink="/applications/{{ application.id }}"
              class="cursor-pointer"
            >
              <h5 class="fw-bold mb-0 text-dark">{{ application.name }}</h5>
              <p class="text-muted small mb-0">
                {{ application.developerCompanyName }}
              </p>
            </div>
          </div>
        </a>

        <a
          routerLink="/applications"
          class="btn btn-light py-2 w-100 text-center fw-bold fs-6"
          >المزيد من التطبيقات</a
        >
      </div>
    </div>
  </div>
</section>
