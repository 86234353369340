import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReplaySubject, takeUntil } from "rxjs";
import {
  ApplicationsCategory,
  ApplicationsPage,
  ApplicationsPageData,
} from "src/app/interfaces/apps-page";
import { Application, Paging } from "src/app/interfaces/landing-page";
import { CMSService } from "src/app/services/cms.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-applications-page",
  templateUrl: "./applications-page.component.html",
  styleUrls: ["./applications-page.component.scss"],
})
export class ApplicationsPageComponent implements OnInit {
  constructor(private route: ActivatedRoute, private cmsService: CMSService) {}
  applications: Application[] = [];
  applicationsPage!: ApplicationsPage;

  applicationsCategories!: ApplicationsCategory[];
  currentCategories!: string[];

  currentPage = 1;
  totalPages!: number;

  unsubscribe$ = new ReplaySubject(1);

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as ApplicationsPageData;

    this.applicationsPage = page.meta;
    this.applicationsCategories = page.data.applicationsCategories;
    this.currentCategories = [this.applicationsCategories[0].id];
    this.getPaginationForCurrentSelectedCategory();
    this.search();
  }

  onPageChangeNext(): void {
    this.applications = [];
    this.currentPage++;
    if (this.currentPage === 1) this.currentPage = 2;
    this.search();
  }

  onPageChangePrev(): void {
    this.applications = [];
    this.currentPage--;
    this.search();
  }

  search() {
    this.cmsService
      .listApplications(this.currentPage, this.currentCategories)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.applications = result;
          window.scrollTo(0, 0);
        },
        error: () => {},
      });
  }


  selectCategoryTab(event: Event) {
    this.applications = [];
    this.currentPage = 1;
    const categoryId = (event.target as HTMLInputElement).value;
    this.currentCategories = [categoryId];
    this.getPaginationForCurrentSelectedCategory();
    this.search();
  }

  getPaginationForCurrentSelectedCategory() {
    this.cmsService
      .listApplicationsPaging( this.currentCategories)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (paging: Paging[]) => {
          this.totalPages = Math.ceil(
            Number(paging[0].count.id) / environment.limit.applicationsLimit
          );
        },
        error: () => {},
      });
  }
}
