import { gql } from "apollo-angular";
import { Experiment, ExperimentsPage } from "../interfaces/experiments-page";

export const GET_EXPERIMENTS_PAGE = gql<
  { page: ExperimentsPage; experiments: Experiment[] },
  { countryId: string }
>`
  query ($countryId: GraphQLStringOrFloat!) {
    page: experiments_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }

    experiments(
      filter: {
        _and: [
          { status: { _eq: "published" } }
          { country: { id: { _eq: $countryId } } }
        ]
      }
      sort: ["-date"]
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      description
      youtubeUrl: youtube_url
      file {
        id
        filename: filename_download
        title
        type
      }
      date
      country {
        id
        name
        image {
          id
          filename: filename_download
          title
        }
      }
    }
  }
`;
