import { gql } from "apollo-angular";
import { Paging, StudentProject } from "../interfaces/landing-page";
import {
  StudentProjectsCategory,
  StudentProjectsPage,
} from "../interfaces/students-projects-page";

export const GET_STUDENTS_PROJECTS_PAGE = gql<
  {
    page: StudentProjectsPage;
    studentProjects: StudentProject[];
    studentProjectsCategories: StudentProjectsCategory[];
    paging: Paging[];
  },
  void
>`
  query {
    page: students_projects_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    studentProjects: students_projects(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 10
    ) {
      id
      title
      presenter
      image {
        id
        filename: filename_download
        title
      }
      date
      description
      document {
        id
        filename: filename_download
        title
        type
      }
    }

    studentProjectsCategories: students_projects_categories(
      filter: { status: { _eq: "published" } }
      sort: ["date_created"]
    ) {
      id
      nameAr
      nameEn
    }

    paging: students_projects_aggregated(
      filter: { status: { _eq: "published" } }
    ) {
      count {
        id
      }
    }
  }
`;

export const LIST_STUDENTS_PROJECTS = gql<
  { studentProjects: StudentProject[]; paging: Paging },
  {
    limit: number;
    page: number;
    categoriesIds: string[];
  }
>`
  query ($limit: Int!, $page: Int!, $categoriesIds: [GraphQLStringOrFloat]!) {
    studentProjects: students_projects(
      filter: {
        _and: [
          { status: { _eq: "published" } }
          { category: { id: { _in: $categoriesIds } } }
        ]
      }
      sort: ["-date_created"]
      limit: $limit
      page: $page
    ) {
      id
      title
      presenter
      image {
        id
        filename: filename_download
        title
      }
      date
      description
      document {
        id
        filename: filename_download
        title
        type
      }
      category {
        id
        nameAr
        nameEn
      }
    }

    paging: students_projects_aggregated(
      filter: {
        _and: [
          { status: { _eq: "published" } }
          { category: { id: { _in: $categoriesIds } } }
        ]
      }
    ) {
      count {
        id
      }
    }
  }
`;

export const LIST_STUDENTS_PROJECTS_PAGING = gql<
  { paging: Paging[] },
  {
    categoriesIds: string[];
  }
>`
  query ($categoriesIds: [GraphQLStringOrFloat]!) {
    paging: students_projects_aggregated(
      filter: {
        _and: [
          { status: { _eq: "published" } }
          { category: { id: { _in: $categoriesIds } } }
        ]
      }
    ) {
      count {
        id
      }
    }
  }
`;
