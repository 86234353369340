import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AIPage, AIPageData } from "src/app/interfaces/AI-page";

@Component({
  selector: "app-ai-info-page",
  templateUrl: "./ai-info-page.component.html",
  styleUrls: ["./ai-info-page.component.scss"],
})
export class AiInfoPageComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  AIPage!: AIPage;
  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as AIPageData;
    this.AIPage = page.meta;
  }
}
