<app-page>
  <page-header></page-header>
  <page-content>
    <div id="page-articles-single" class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <img
              [src]="openEducationPlatform.image | imageLink"
              class="img-fluid d-block mb-4"
              alt=""
            />
          </div>
          <div class="col">
            <h5 class="fw-bold mb-4">
              {{ openEducationPlatform.name }}
            </h5>
            <p [innerHTML]="openEducationPlatform.description"></p>
          </div>
        </div>

        <h5
          *ngIf="openEducationPlatform.images.length > 0"
          class="fw-bold text-center my-4"
        >
          صور من المنصة
        </h5>

        <div class="row">
          <div
            *ngFor="let image of openEducationPlatform.images; index as i"
            class="col-lg-6 col-md-4 col-sm-6 mt-4 mx-auto"
          >
            <a>
              <div class="article-card px-3 mb-4 mb-md-5">
                <img
                  class="d-block w-100 cover cursor-pointer"
                  src="{{ assetsURI }}/{{ image.imageId.id }}"
                  [alt]="openEducationPlatform.name"
                  style="height: 300px"
                  (click)="
                    openZoomDialog(image.imageId.id, openEducationPlatform.name)
                  "
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
