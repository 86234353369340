import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Report } from "src/app/interfaces/landing-page";
import { SingleReportPageData } from "src/app/interfaces/single-report-page";

@Component({
  selector: "app-reports-single-page",
  templateUrl: "./reports-single-page.component.html",
  styleUrls: ["./reports-single-page.component.scss"],
})
export class ReportsSinglePageComponent implements OnInit {
  reports!: Report[];
  report!: Report;
  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const { meta, ...page } = this.route.snapshot.data[
        "page"
      ] as SingleReportPageData;

      this.report = page.data.report;
      this.reports = page.data.reports;
    });
  }
}
