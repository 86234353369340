<section id="goaie-committee">
  <div class="container">
    <div class="text-center">
      <div class="title bg-pattern d-inline-block bg-gray mb-3 mt-5">
        فريق العمل
      </div>
    </div>

    <div flickity [flickityConfig]="flickityConfig" class="slider mt-5">
      <div *ngFor="let workTeamMember of workTeamMembers" class="single-slide">
        <a>
          <div class="committee-card card">
            <div class="card-body text-center">
              <div class="image">
                <img
                  [src]="workTeamMember.avatar | imageLink"
                  class="profile-image mb-3 img-fluid contain"
                  [alt]="workTeamMember.avatar.title"
                  style="object-fit: unset"
                />
                <img
                  [src]="workTeamMember.country.image | imageLink"
                  class="flag"
                  [alt]="workTeamMember.country.image.title"
                />
              </div>
              <h6 class="fw-bold text-dark mb-0">{{ workTeamMember.name }}</h6>
              <h6 class="fw-bold text-dark small mt-2">
                {{ workTeamMember.country.name }}
              </h6>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
