import { gql } from "apollo-angular";
import { Application, Paging } from "../interfaces/landing-page";
import { ApplicationsPage } from "../interfaces/apps-page";

export const GET_APPLICATIONS_PAGE = gql<
  {
    page: ApplicationsPage;
    applications: Application[];
    paging: Paging[];
  },
  void
>`
  query {
    page: apps_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    applications: ai_apps(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 10
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      developerCompanyName: developer_company_name
    }
    paging: ai_apps_aggregated(filter: { status: { _eq: "published" } }) {
      count {
        id
      }
    }
  }
`;

export const LIST_APPLICATIONS = gql<
  { applications: Application[]; paging: Paging },
  {
    limit: number;
    page: number;
  }
>`
  query ($limit: Int!, $page: Int!) {
    applications: ai_apps(
      filter: { _and: [{ status: { _eq: "published" } }] }
      sort: ["-date_created"]
      limit: $limit
      page: $page
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      developerCompanyName: developer_company_name
    }

    paging: ai_apps_aggregated(
      filter: { _and: [{ status: { _eq: "published" } }] }
    ) {
      count {
        id
      }
    }
  }
`;

export const LIST_APPLICATIONS_PAGING = gql<{ paging: Paging[] }, void>`
  query{
    paging: ai_apps_aggregated(
      filter: {
        _and: [
          { status: { _eq: "published" } }
        ]
      }
    ) {
      count {
        id
      }
    }
  }
`;
