import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable, map } from "rxjs";
import { ContactUsPage } from "../interfaces/contact-us-page";

import { GET_CONTACT_US_PAGE } from "../queries/contact-us-page.query";

import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
  Application,
  Article,
  Camp,
  Hackathon,
  OpenEducationPlatform,
  Paging,
  Report,
  StudentProject,
  Tool,
} from "../interfaces/landing-page";
import {
  LIST_ARTICLES,
  LIST_ARTICLES_PAGING,
} from "../queries/articles-page.query";
import {
  LIST_REPORTS,
  LIST_REPORTS_PAGING,
} from "../queries/reports-page.query";
import { LIST_TOOLS, LIST_TOOLS_PAGING } from "../queries/tools-page.query";
import {
  LIST_APPLICATIONS,
  LIST_APPLICATIONS_PAGING,
} from "../queries/apps-page.query";
import {
  LIST_OPEN_EDUCATION_PLATFORMS,
  LIST_OPEN_EDUCATION_PLATFORMS_PAGING,
} from "../queries/open-education-platforms-page.query";
import { LIST_CAMPS, LIST_CAMPS_PAGING } from "../queries/camps-page.query";
import {
  LIST_STUDENTS_PROJECTS,
  LIST_STUDENTS_PROJECTS_PAGING,
} from "../queries/students-projects-page.query";
import {
  LIST_HACKATHONS,
  LIST_HACKATHONS_PAGING,
} from "../queries/hackathons-page.query";

@Injectable({
  providedIn: "root",
})
export class CMSService {
  constructor(private apollo: Apollo, private httpClient: HttpClient) {}

  getContactUsDetails(): Observable<ContactUsPage> {
    return this.apollo
      .query({
        query: GET_CONTACT_US_PAGE,
      })
      .pipe(
        map((result): ContactUsPage => {
          const contactus = result.data.page;
          return contactus;
        })
      );
  }

  // For Articles
  listArticles(page: number, categoriesIds: string[]): Observable<Article[]> {
    return this.apollo
      .query({
        query: LIST_ARTICLES,
        variables: {
          page,
          limit: environment.limit.articlesLimit,
          categoriesIds,
        },
      })
      .pipe(
        map((result): Article[] => {
          const { articles } = result.data;
          return articles;
        })
      );
  }

  listArticlesPaging(categoriesIds: string[]): Observable<Paging[]> {
    return this.apollo
      .query({
        query: LIST_ARTICLES_PAGING,
        variables: {
          categoriesIds,
        },
      })
      .pipe(
        map((result): Paging[] => {
          const { paging } = result.data;
          return paging;
        })
      );
  }

  // For Reports

  listReports(page: number): Observable<Report[]> {
    return this.apollo
      .query({
        query: LIST_REPORTS,
        variables: {
          page,
          limit: environment.limit.reportsLimit,
        },
      })
      .pipe(
        map((result): Report[] => {
          const { reports } = result.data;
          return reports;
        })
      );
  }

  listReportsPaging(): Observable<Paging[]> {
    return this.apollo
      .query({
        query: LIST_REPORTS_PAGING,
      })
      .pipe(
        map((result): Paging[] => {
          const { paging } = result.data;
          return paging;
        })
      );
  }

  // For Tools

  listTools(page: number): Observable<Tool[]> {
    return this.apollo
      .query({
        query: LIST_TOOLS,
        variables: {
          page,
          limit: environment.limit.toolsLimit,
        },
      })
      .pipe(
        map((result): Tool[] => {
          const { tools } = result.data;
          return tools;
        })
      );
  }

  listToolsPaging(): Observable<Paging[]> {
    return this.apollo
      .query({
        query: LIST_TOOLS_PAGING,
      })
      .pipe(
        map((result): Paging[] => {
          const { paging } = result.data;
          return paging;
        })
      );
  }

  // For Apps

  listApplications(page: number): Observable<Application[]> {
    return this.apollo
      .query({
        query: LIST_APPLICATIONS,
        variables: {
          page,
          limit: environment.limit.applicationsLimit,
        },
      })
      .pipe(
        map((result): Application[] => {
          const { applications } = result.data;
          return applications;
        })
      );
  }

  listApplicationsPaging(): Observable<Paging[]> {
    return this.apollo
      .query({
        query: LIST_APPLICATIONS_PAGING,
      })
      .pipe(
        map((result): Paging[] => {
          const { paging } = result.data;
          return paging;
        })
      );
  }

  // For Open Education Platforms

  listOpenEducationPlatforms(
    page: number
  ): Observable<OpenEducationPlatform[]> {
    return this.apollo
      .query({
        query: LIST_OPEN_EDUCATION_PLATFORMS,
        variables: {
          page,
          limit: environment.limit.openEducationPlatformsLimit,
        },
      })
      .pipe(
        map((result): OpenEducationPlatform[] => {
          const { openEducationPlatforms } = result.data;
          return openEducationPlatforms;
        })
      );
  }

  listOpenEducationPlatformsPaging(): Observable<Paging[]> {
    return this.apollo
      .query({
        query: LIST_OPEN_EDUCATION_PLATFORMS_PAGING,
      })
      .pipe(
        map((result): Paging[] => {
          const { paging } = result.data;
          return paging;
        })
      );
  }

  // For Camps

  listCamps(page: number): Observable<Camp[]> {
    return this.apollo
      .query({
        query: LIST_CAMPS,
        variables: {
          page,
          limit: environment.limit.campsLimit,
        },
      })
      .pipe(
        map((result): Camp[] => {
          const { camps } = result.data;
          return camps;
        })
      );
  }

  listCampsPaging(): Observable<Paging[]> {
    return this.apollo
      .query({
        query: LIST_CAMPS_PAGING,
      })
      .pipe(
        map((result): Paging[] => {
          const { paging } = result.data;
          return paging;
        })
      );
  }

  // For Students Projects

  listStudentProjects(
    page: number,
  ): Observable<StudentProject[]> {
    return this.apollo
      .query({
        query: LIST_STUDENTS_PROJECTS,
        variables: {
          page,
          limit: environment.limit.studentProjectsLimit,
        },
      })
      .pipe(
        map((result): StudentProject[] => {
          const { studentProjects } = result.data;
          return studentProjects;
        })
      );
  }

  listStudentProjectsPaging(): Observable<Paging[]> {
    return this.apollo
      .query({
        query: LIST_STUDENTS_PROJECTS_PAGING,
      })
      .pipe(
        map((result): Paging[] => {
          const { paging } = result.data;
          return paging;
        })
      );
  }

  // For Hackathons

  listHackathons(page: number): Observable<Hackathon[]> {
    return this.apollo
      .query({
        query: LIST_HACKATHONS,
        variables: {
          page,
          limit: environment.limit.hackathonsLimit,
        },
      })
      .pipe(
        map((result): Hackathon[] => {
          const { hackathons } = result.data;
          return hackathons;
        })
      );
  }

  listHackathonsPaging(): Observable<Paging[]> {
    return this.apollo
      .query({
        query: LIST_HACKATHONS_PAGING,
      })
      .pipe(
        map((result): Paging[] => {
          const { paging } = result.data;
          return paging;
        })
      );
  }
}
