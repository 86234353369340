<app-page>
  <page-header></page-header>
  <page-content>
    <div id="page-reports" class="py-5">
      <div class="container">
        <div class="row">
          <div
            *ngFor="let report of reports"
            class="col-lg-3 col-md-4 col-sm-6"
          >
            <a routerLink="/reports/{{ report.id }}">
              <div class="report-card px-3 mb-4 mb-md-5">
                <img
                  class="rounded-4 d-block w-100"
                  [src]="report.image | imageLink"
                  [alt]="report.image.title"
                />
                <h6 class="text-center text-dark fw-bold mt-3">
                  {{ report.title }}
                </h6>
              </div>
            </a>
          </div>
        </div>

        <nav
          aria-label="Page navigation"
          class="mt-4 pagination justify-content-center"
        >
          <ul class="pagination">
            <li
              class="page-item text-center"
              [ngClass]="{ disabled: currentPage === 1 }"
            >
              <a
                class="page-link cursor-pointer"
                style="width: 100px; font-size: large; text-align: center"
                (click)="onPageChangePrev()"
                >السابق</a
              >
            </li>
            <li class="page-item text-center">
              <a class="page-link active">{{ currentPage }}</a>
            </li>
            <li
              class="page-item text-center"
              [ngClass]="{ disabled: currentPage >= totalPages }"
            >
              <a
                class="page-link cursor-pointer"
                style="width: 100px; font-size: large; text-align: center"
                (click)="onPageChangeNext()"
                >التالى</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
