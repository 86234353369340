<section id="goaie-articles">
  <div class="container">
    <div class="text-center">
      <div class="title bg-pattern d-inline-block bg-green mb-3 mt-5">
        مقالات علمية
      </div>
      <div class="subtitle text-center mx-auto mt-2">
        كل ما هو جديد عن تقنيات الذكاء الاصطناعي والتطور السريع في هذا المجال
      </div>
    </div>

    <div flickity [flickityConfig]="flickityConfig" class="slider mt-5">
      <div *ngFor="let article of articles" class="single-slide">
        <a target="_self" routerLink="/articles/{{ article.id }}">
          <div class="article-card px-3 mb-4 mb-md-5 d-flex flex-column justify-content-center">
            <img
              class="rounded-4 d-block contain img-fluid"
              [src]="article.image | imageLink"
              [alt]="article.image.title"
            />
            <h6 class="text-center text-dark fw-bold mt-3 line-clamp-2">
              {{ article.title }}
            </h6>
          </div>
        </a>
      </div>
    </div>

    <div class="text-center mt-3">
      <a routerLink="/articles" class="btn btn-outline-dark"
        >المزيد من المقالات</a
      >
    </div>
  </div>
</section>
