// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  graphqlURI: "https://test-goaie.abegs.org/cms/graphql",
  assetsURI: "https://test-goaie.abegs.org/cms/assets",
  cmsAdminURL: "https://test-goaie.abegs.org/cms/admin",
  restApi: "https://test-goaie.abegs.org/cms",
  fileAPI: "https://test-goaie.abegs.org/cms/files",
  portalURI: "https://testserver.abegs.org",
  serverURI: "https://test-goaie.abegs.org/goaie/api",
  goaieAppURI: "https://test-ai.abegs.org",

  limit: {
    articlesLimit: 15,
    reportsLimit: 16,
    toolsLimit: 24,
    applicationsLimit: 24,
    openEducationPlatformsLimit: 18,
    campsLimit: 16,
    studentProjectsLimit: 16,
    hackathonsLimit: 16,
  },
  analytics: {
    measurementId: "G-J8Y3FJXB0W",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
