import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OpenEducationPlatform } from "src/app/interfaces/landing-page";
import { SingleOpenEducationPlatformPageData } from "src/app/interfaces/single-open-education-platforms-page";
import { AppImageZoomComponent } from "../../shared/app-image-zoom/app-image-zoom.component";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-open-education-platforms-single-page",
  templateUrl: "./open-education-platforms-single-page.component.html",
  styleUrls: ["./open-education-platforms-single-page.component.scss"],
})
export class OpenEducationPlatformsSinglePageComponent implements OnInit {
  openEducationPlatform!: OpenEducationPlatform;
  assetsURI = environment.assetsURI;

  constructor(private route: ActivatedRoute, public dialog: MatDialog) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const { meta, ...page } = this.route.snapshot.data[
        "page"
      ] as SingleOpenEducationPlatformPageData;

      this.openEducationPlatform = page.data.openEducationPlatform;
    });
  }

  openZoomDialog(imageId: string, alt: string): void {
    const dialogRef = this.dialog.open(AppImageZoomComponent, {
      data: { imageId: imageId, alt: alt },
    });
  }
}
