import { gql } from "apollo-angular";
import { OpenEducationPlatform } from "../interfaces/landing-page";
import { SingleOpenEducationPlatformPage } from "../interfaces/single-open-education-platforms-page";

export const GET_SINGLE_OPEN_EDUCATION_PLATFORM_PAGE = gql<
  {
    page: SingleOpenEducationPlatformPage;
    openEducationPlatform: OpenEducationPlatform;
    openEducationPlatforms: OpenEducationPlatform[];
  },
  { platformId: string }
>`
  query ($platformId: ID!) {
    page: open_platforms_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    openEducationPlatform: open_education_platforms_by_id(id: $platformId) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      description
      images {
        imageId: directus_files_id {
          id
        }
      }
    }

    openEducationPlatforms: open_education_platforms(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 4
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      description
    }
  }
`;
