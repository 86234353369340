import { gql } from "apollo-angular";
import { Paging, Report } from "../interfaces/landing-page";
import { ReportsPage } from "../interfaces/reports-page-page";

export const GET_REPORTS_PAGE = gql<
  {
    page: ReportsPage;
    reports: Report[];
    paging: Paging[];
  },
  void
>`
  query {
    page: reports_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    reports(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 10
    ) {
      id
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      publisher
      reportUrl: report_url
      publishingDate: publishing_date
    }

    paging: reports_aggregated(filter: { status: { _eq: "published" } }) {
      count {
        id
      }
    }
  }
`;

export const LIST_REPORTS = gql<
  { reports: Report[]; paging: Paging },
  {
    limit: number;
    page: number;
  }
>`
  query ($limit: Int!, $page: Int!) {
    reports(
      filter: { _and: [{ status: { _eq: "published" } }] }
      sort: ["-date_created"]
      limit: $limit
      page: $page
    ) {
      id
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      publisher
      reportUrl: report_url
      publishingDate: publishing_date
    }

    paging: reports_aggregated(
      filter: { _and: [{ status: { _eq: "published" } }] }
    ) {
      count {
        id
      }
    }
  }
`;

export const LIST_REPORTS_PAGING = gql<{ paging: Paging[] }, void>`
  query  {
    paging: reports_aggregated(
      filter: {
        _and: [
          { status: { _eq: "published" } }
        ]
      }
    ) {
      count {
        id
      }
    }
  }
`;
