import { gql } from "apollo-angular";
import { WorldCountry } from "../interfaces/landing-page";
import { WorldCountriesPage } from "../interfaces/world-countries-page";

export const GET_WORLD_COUNTRIES_PAGE = gql<
  { page: WorldCountriesPage; worldCountries: WorldCountry[] },
  void
>`
  query {
    page: world_countries_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }

    worldCountries: world_countries(
      filter: { status: { _eq: "published" } }
      sort: ["order"]
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      order
    }
  }
`;
