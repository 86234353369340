import { Component, Input, OnInit } from "@angular/core";
import { LandingPage } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-home-certificate",
  templateUrl: "./home-certificate.component.html",
  styleUrls: ["./home-certificate.component.scss"],
})
export class HomeCertificateComponent implements OnInit {
  @Input() page!: LandingPage;
  constructor() {}

  ngOnInit(): void {}
}
