import { gql } from "apollo-angular";
import { Article, Paging } from "../interfaces/landing-page";
import {
  ArticlesCategory,
  ArticlesPage,
} from "../interfaces/articles-page-page";

export const GET_ARTICLES_PAGE = gql<
  {
    page: ArticlesPage;
    articles: Article[];
    articlesCategories: ArticlesCategory[];
    paging: Paging[];
  },
  void
>`
  query {
    page: articles_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    articles(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 10
    ) {
      id
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      publisher
      articleUrl: article_url
      category {
        id
        nameAr
        nameEn
      }
      publishingDate: publishing_date
    }

    articlesCategories: articles_categories(
      filter: { status: { _eq: "published" } }
      sort: ["date_created"]
    ) {
      id
      nameAr
      nameEn
    }

    paging: articles_aggregated(filter: { status: { _eq: "published" } }) {
      count {
        id
      }
    }
  }
`;

export const LIST_ARTICLES = gql<
  { articles: Article[]; paging: Paging },
  {
    limit: number;
    page: number;
    categoriesIds: string[];
  }
>`
  query ($limit: Int!, $page: Int!, $categoriesIds: [GraphQLStringOrFloat]!) {
    articles(
      filter: {
        _and: [
          { status: { _eq: "published" } }
          { category: { id: { _in: $categoriesIds } } }
        ]
      }
      sort: ["-date_created"]
      limit: $limit
      page: $page
    ) {
      id
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      publisher
      articleUrl: article_url
      category {
        id
        nameAr
        nameEn
      }
      publishingDate: publishing_date
    }

    paging: articles_aggregated(
      filter: {
        _and: [
          { status: { _eq: "published" } }
          { category: { id: { _in: $categoriesIds } } }
        ]
      }
    ) {
      count {
        id
      }
    }
  }
`;

export const LIST_ARTICLES_PAGING = gql<
  { paging: Paging[] },
  {
    categoriesIds: string[];
  }
>`
  query ($categoriesIds: [GraphQLStringOrFloat]!) {
    paging: articles_aggregated(
      filter: {
        _and: [
          { status: { _eq: "published" } }
          { category: { id: { _in: $categoriesIds } } }
        ]
      }
    ) {
      count {
        id
      }
    }
  }
`;
