import { gql } from "apollo-angular";
import { Paging, Report } from "../interfaces/landing-page";
import { ReportsCategory, ReportsPage } from "../interfaces/reports-page";

export const GET_REPORTS_PAGE = gql<
  {
    page: ReportsPage;
    reports: Report[];
    reportsCategories: ReportsCategory[];
    paging: Paging[];
  },
  void
>`
  query {
    page: reports_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    reports(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 10
    ) {
      id
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      publisher
      reportUrl: report_url
      publishingDate: publishing_date
    }

    reportsCategories: reports_categories(
      filter: { status: { _eq: "published" } }
      sort: ["date_created"]
    ) {
      id
      nameAr
      nameEn
    }

    paging: reports_aggregated(filter: { status: { _eq: "published" } }) {
      count {
        id
      }
    }
  }
`;

export const LIST_REPORTS = gql<
  { reports: Report[]; paging: Paging },
  {
    limit: number;
    page: number;
    categoriesIds: string[];
  }
>`
  query ($limit: Int!, $page: Int!, $categoriesIds: [GraphQLStringOrFloat]!) {
    reports(
      filter: {
        _and: [
          { status: { _eq: "published" } }
          { category: { id: { _in: $categoriesIds } } }
        ]
      }
      sort: ["-date_created"]
      limit: $limit
      page: $page
    ) {
      id
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      category {
        id
        nameAr
        nameEn
      }
      publisher
      reportUrl: report_url
      publishingDate: publishing_date
    }

    paging: reports_aggregated(
      filter: {
        _and: [
          { status: { _eq: "published" } }
          { category: { id: { _in: $categoriesIds } } }
        ]
      }
    ) {
      count {
        id
      }
    }
  }
`;

export const LIST_REPORTS_PAGING = gql<
  { paging: Paging[] },
  {
    categoriesIds: string[];
  }
>`
  query ($categoriesIds: [GraphQLStringOrFloat]!) {
    paging: reports_aggregated(
      filter: {
        _and: [
          { status: { _eq: "published" } }
          { category: { id: { _in: $categoriesIds } } }
        ]
      }    ) {
      count {
        id
      }
    }
  }
`;
