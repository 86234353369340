<app-page>
  <page-header></page-header>
  <page-content>
    <div id="page-ai" class="py-3">
      <div class="container">
        <div class="bg-very-light-gray bg-opacity-25 rounded-3 py-4 px-md-5">
          <h5 class="fw-bold">مقدمة</h5>
          <p [innerHTML]="AIPage.introduction"></p>
        </div>

        <section class="row flex-row-reverse my-5">
          <div class="col-md-4">
            <img
              [src]="AIPage.image1 | imageLink"
              class="img-fluid rounded-3"
              [alt]="AIPage.image1.title"
            />
          </div>
          <div class="col">
            <p [innerHTML]="AIPage.text1"></p>
          </div>
        </section>

        <section class="my-5">
          <p [innerHTML]="AIPage.text2"></p>
        </section>

        <section class="row my-5">
          <div class="col-md-4">
            <img
              [src]="AIPage.image2 | imageLink"
              class="img-fluid rounded-3"
              [alt]="AIPage.image2.title"
            />
          </div>
          <div class="col">
            <p [innerHTML]="AIPage.text3"></p>
          </div>
        </section>

        <section class="my-5">
          <p [innerHTML]="AIPage.text4"></p>
        </section>

        <div
          class="d-flex flex-column flex-md-row justify-content-around ps-lg-10 mt-6 mb-10 mb-md-0"
        >
          <span></span>
          <a
            [href]="AIPage.document | fileLink"
            target="_blank"
            class="btn btn-primary px-4"
          >
            وثيقة الذكاء الاصطناعي
            <i class="fa-solid fa-arrow-right-to-bracket fa-rotate-90 ms-2"></i>
          </a>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
