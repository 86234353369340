<!-- <div style="background-color: #363636" class="text-light py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-8">
        <ul class="footer-links list-unstyled mb-5 mt-4">
          <li
            *ngFor="let link of footerLinks"
            class="list-unstyled-item ps-2 ms-3 mb-3"
          >
            <a
              *ngIf="!link.external"
              [routerLink]="link.href"
              class="cursor-pointer"
              >{{ link.title }}</a
            >
            <a
              *ngIf="link.external"
              [href]="link.href"
              target="_blank"
              class="cursor-pointer"
              >{{ link.title }}</a
            >
          </li>
        </ul>
      </div>
      <div class="col">
        <div class="">
          <div class="d-flex social-media-links justify-content-md-end">
            <a
              *ngFor="let link of socialMediaLinks"
              class="ms-md-3 me-3 me-md-0 text-light"
              target="_blank"
              href="{{ link.href }}"
            >
              <i class="{{ link.iconClass }}"></i>
            </a>
          </div>
        </div>

        <div class="mt-4 text-light small">
          <ul>
            <li>
              <p class="">
                <a class="text-light" href="tel: 00966114800555">{{
                  contact.phone
                }}</a>
              </p>
            </li>
            <li>
              <p class="">
                <a class="text-light">{{ contact.address }}</a>
              </p>
            </li>

            <li>
              <p class="">
                <a class="text-light" href="mailto: abegsai@abegs.org">
                  {{ contact.email }}
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="footer-font-override">
  <div class="bg-dark text-light pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-lg-6">
          <div
            class="d-flex d-md-block flex-column align-items-center justify-content-center"
          >
            <h6 class="text-primary fw-bold footer-header">
              مكتب التربية العربي لدول الخليج
            </h6>
            <hr
              style="width: 28ch"
              class="border border-primary border-1 opacity-75"
            />
          </div>

          <ul class="footer-links list-unstyled mx-3 mx-md-0">
            <li
              *ngFor="let link of footerLinks"
              class="list-unstyled-item ps-2 ms-3 mb-3"
              style="font-size: 0.85rem; color: #ffffff"
            >
              <a [routerLink]="link.href" class="cursor-pointer">{{
                link.title
              }}</a>
            </li>
          </ul>
        </div>
        <div *ngIf="contact" class="col">
          <div
            class="d-flex d-md-block flex-column justify-content-center align-items-center"
          >
            <h6 class="text-primary fw-bold footer-header">تواصل معنا</h6>
            <hr
              style="width: 12ch"
              class="border border-primary border-1 opacity-75"
            />
          </div>

          <div class="text-light" style="font-size: 0.85rem; color: #ffffff">
            <p class="link-height mb-0">
              <i class="fa-solid fa-location-dot"></i>
              المملكة العربية السعودية - الرياض - الرمز البريدي 12622 - حي
              الوزارات 8184 - 3138
            </p>

            <p class="link-height mb-0">
              <a class="text-light" href="tel:  966112664470">
                <i class="fa-solid fa-phone"></i>
                {{ contact.phone }}
              </a>
            </p>

            <p class="link-height mb-0">
              <a class="text-light" href="mailto: abegs@abegs.org">
                <i class="fa-solid fa-envelope"></i>
                {{ contact.email }}
              </a>
            </p>
            <!-- <p  class="link-height mb-0">
              <fa-icon [icon]="faQuestion" flip="horizontal" class="me-3 info-icon"></fa-icon>
              <ng-container>هل لديك أي أسئلة تود طرحها؟</ng-container>
            </p> -->

            <a
              href="mailto: abegsai@abegs.org"
              class="btn btn-primary fw-bold mt-4"
              >تواصل معنا</a
            >

            <div
              id="social-media"
              class="social-media-links mt-4 d-flex justify-content-center justify-content-md-start align-content-center gap-4"
            >
              <a
                *ngFor="let link of socialMediaLinks"
                class="text-light tw-leading-none"
                target="_blank"
                href="{{ link.href }}"
              >
                <i class="{{ link.iconClass }}"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="container text-center">
        <p
          class="footer text-center text-muted small py-3"
          style="margin-bottom: 0"
        >
          جميع الحقوق محفوظة © لمكتب التربية العربي لدول الخليج {{currentYear}}
        </p>
      </div>
    </div>
  </div>

  <button
    #scrollTopBtn
    routerLink="."
    class="btn btn-primary rounded-circle scroll-top-btn shadow"
  >
    <i class="fa-solid fa-arrow-up"></i>
  </button>
</div>
