import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { ApplicationsPageData } from "../interfaces/apps-page";
import { GET_APPLICATIONS_PAGE } from "../queries/apps-page.query";

@Injectable({
  providedIn: "root",
})
export class AppsPageResolver implements Resolve<ApplicationsPageData> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<ApplicationsPageData> {
    return this.apollo
      .query({
        query: GET_APPLICATIONS_PAGE,
      })
      .pipe(
        map((result): ApplicationsPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "تطبيقات الذكاء الاصطناعي",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            breadcrumbs: [{ title: meta.title }],
            data: page,
            meta,
          };
        })
      );
  }
}
