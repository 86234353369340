import { gql } from "apollo-angular";
import {
  LandingContributionGoal,
  LandingSlider,
  Report,
  Application,
  Article,
  GulfCountry,
  Hackathon,
  OpenEducationPlatform,
  StudentProject,
  Tool,
  WorldCountry,
  workTeamMember,
  LandingPage,
  Camp,
} from "../interfaces/landing-page";

export const GET_LANDING_PAGE_DATA = gql<
  {
    page: LandingPage;
    goals: LandingContributionGoal[];
    landingSliders: LandingSlider[];
    reports: Report[];
    articles: Article[];
    gulfCountries: GulfCountry[];
    worldCountries: WorldCountry[];
    tools: Tool[];
    applications: Application[];
    openEducationPlatforms: OpenEducationPlatform[];
    workTeamMembers: workTeamMember[];
    camps: Camp[];
    hackathons: Hackathon[];
    studentProjects: StudentProject[];
  },
  void
>`
  query {
    page: landing_page {
      contributionWord: contribution_word
      contributionImage: contribution_image {
        id
        filename: filename_download
        title
      }
      AIDefineImage: ai_define_image {
        id
        filename: filename_download
        title
      }
      AIDefinitionText: ai_definition_text
      AIDefinitionDocument: ai_definition_document {
        id
        filename: filename_download
        title
        type
      }
    }
    goals: landing_contribution_goals(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
    ) {
      id
      goal
    }
    landingSliders: landing_slider(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
    ) {
      id
      image {
        id
        filename: filename_download
        title
      }
      title
      subTitle: sub_title
    }
    reports(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 5
    ) {
      id
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      publisher
      reportUrl: report_url
      publishingDate: publishing_date
    }
    articles(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 10
    ) {
      id
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      publisher
      reportUrl: article_url
      category {
        id
        nameAr
        nameEn
      }
      publishingDate: publishing_date
    }

    gulfCountries: gulf_countries(
      filter: { status: { _eq: "published" } }
      sort: ["order"]
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      order
    }
    worldCountries: world_countries(
      filter: { status: { _eq: "published" } }
      sort: ["order"]
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      order
    }

    tools: ai_tools(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 3
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      developerCompanyName: developer_company_name
    }

    applications: ai_apps(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 3
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      developerCompanyName: developer_company_name
    }

    openEducationPlatforms: open_education_platforms(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 6
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      description

    }

    workTeamMembers: work_team(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
    ) {
      id
      name
      avatar {
        id
        filename: filename_download
        title
      }
      jobPosition: job_position
      country {
        id
        name
        image {
          id
          filename: filename_download
          title
        }
        order
      }
    }

    camps(
      filter: { status: { _eq: "published" }, active: { _eq: true } }
      sort: ["-date_created"]
      limit: 1
    ) {
      id
      title
      startDate: start_date
      endDate: end_date
      image {
        id
        filename: filename_download
        title
      }
      aboutHackathonImage: about_camp_image {
        id
        filename: filename_download
        title
      }
      totalHours: total_hours
      instructors {
        id
        name
        image {
          id
          filename: filename_download
          title
        }
        gender
        hackathon: fk_instructor_hackathon {
          id
        }
      }
      targetAudience: target_audience
      coursesNumber: courses_number
      specialization
      summary
      description
      conditions
      active
    }

    hackathons(
      filter: { status: { _eq: "published" }, active: { _eq: true } }
      sort: ["-date_created"]
      limit: 1
    ) {
      id
      title
      startDate: start_date
      endDate: end_date
      image {
        id
        filename: filename_download
        title
      }
      aboutHackathonImage: about_hackathon_image {
        id
        filename: filename_download
        title
      }
      totalHours: total_hours
      instructors {
        id
        name
        image {
          id
          filename: filename_download
          title
        }
        gender
        hackathon: fk_instructor_hackathon {
          id
        }
      }
      targetAudience: target_audience
      coursesNumber: courses_number
      specialization
      summary
      description
      conditions
      active
    }
    studentProjects: students_projects(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
    ) {
      id
      title
      presenter
      image {
        id
        filename: filename_download
        title
      }
      date
      description
      document {
        id
        filename: filename_download
        title
        type
      }
    }
  }
`;
