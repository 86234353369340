import { gql } from "apollo-angular";
import { Camp } from "../interfaces/landing-page";
import { SingleCampPage } from "../interfaces/single-camp-page";

export const GET_SINGLE_CAMPS_PAGE = gql<
  { page: SingleCampPage; camp: Camp; camps: Camp[] },
  { campId: string }
>`
  query ($campId: ID!) {
    page: training_camps_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    camp: camps_by_id(id: $campId) {
      id
      title
      startDate: start_date
      endDate: end_date
      image {
        id
        filename: filename_download
        title
      }
      aboutHackathonImage: about_camp_image {
        id
        filename: filename_download
        title
      }
      totalHours: total_hours
      instructors {
        id
        name
        image {
          id
          filename: filename_download
          title
        }
        gender
        hackathon: fk_instructor_hackathon {
          id
        }
      }
      targetAudience: target_audience
      coursesNumber: courses_number
      specialization
      summary
      description
      conditions
      active
      registerationURL: registeration_url
    }

    camps(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 4
    ) {
      id
      title
      startDate: start_date
      endDate: end_date
      image {
        id
        filename: filename_download
        title
      }
      aboutHackathonImage: about_camp_image {
        id
        filename: filename_download
        title
      }
      totalHours: total_hours
      instructors {
        id
        name
        image {
          id
          filename: filename_download
          title
        }
        gender
        hackathon: fk_instructor_hackathon {
          id
        }
      }
      targetAudience: target_audience
      coursesNumber: courses_number
      specialization
      summary
      description
      conditions
      active
    }
  }
`;
