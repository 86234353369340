import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReplaySubject, takeUntil } from "rxjs";
import {
  ToolsPage,
  ToolsPageData,
} from "src/app/interfaces/tools-page";
import { Tool, Paging } from "src/app/interfaces/landing-page";
import { CMSService } from "src/app/services/cms.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-tools-page",
  templateUrl: "./tools-page.component.html",
  styleUrls: ["./tools-page.component.scss"],
})
export class ToolsPageComponent implements OnInit {
  constructor(private route: ActivatedRoute, private cmsService: CMSService) {}
  tools: Tool[] = [];
  toolsPage!: ToolsPage;
  currentPage = 1;
  totalPages!: number;

  unsubscribe$ = new ReplaySubject(1);

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as ToolsPageData;

    this.toolsPage = page.meta;
    this.listToolsPaging();
    this.search();
  }

  onPageChangeNext(): void {
    this.tools = [];
    this.currentPage++;
    if (this.currentPage === 1) this.currentPage = 2;
    this.search();
  }

  onPageChangePrev(): void {
    this.tools = [];
    this.currentPage--;
    this.search();
  }

  search() {
    this.cmsService
      .listTools(this.currentPage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.tools = result;
          window.scrollTo(0, 0);
        },
        error: () => {},
      });
  }
  listToolsPaging() {
    this.cmsService
      .listToolsPaging()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (paging: Paging[]) => {
          this.totalPages = Math.ceil(
            Number(paging[0].count.id) / environment.limit.toolsLimit
          );
        },
        error: () => {},
      });
  }
}
