import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Tool } from "src/app/interfaces/landing-page";
import { SingleToolPageData } from "src/app/interfaces/single-tool-page";
import { environment } from "src/environments/environment";
import { AppImageZoomComponent } from "../../shared/app-image-zoom/app-image-zoom.component";

@Component({
  selector: "app-tools-single-page",
  templateUrl: "./tools-single-page.component.html",
  styleUrls: ["./tools-single-page.component.scss"],
})
export class ToolsSinglePageComponent implements OnInit {
  tools!: Tool[];
  tool!: Tool;
  assetsURI = environment.assetsURI;

  constructor(private route: ActivatedRoute, public dialog: MatDialog) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const { meta, ...page } = this.route.snapshot.data[
        "page"
      ] as SingleToolPageData;

      this.tool = page.data.tool;
      this.tools = page.data.tools;
    });
  }
  openZoomDialog(imageId: string, alt: string): void {
    const dialogRef = this.dialog.open(AppImageZoomComponent, {
      data: { imageId: imageId, alt: alt },
    });
  }
}
