import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { SingleOpenEducationPlatformPageData } from "../interfaces/single-open-education-platforms-page";
import { GET_SINGLE_OPEN_EDUCATION_PLATFORM_PAGE } from "../queries/single-open-education-platform-query";

@Injectable({
  providedIn: "root",
})
export class SingleOpenEducationPlatformsPageResolver
  implements Resolve<SingleOpenEducationPlatformPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<SingleOpenEducationPlatformPageData> {
    return this.apollo
      .query({
        query: GET_SINGLE_OPEN_EDUCATION_PLATFORM_PAGE,
        variables: {
          platformId: route.params["platformId"],
        },
      })
      .pipe(
        map((result): SingleOpenEducationPlatformPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: meta.title,
            breadcrumbs: [
              { title: meta.title, routerLink: "/open-education-platforms" },
              { title: page.openEducationPlatform.name },
            ],
            data: page,
            meta,
            hero: {
              title: meta.title,
              image: meta.image,
            },
          };
        })
      );
  }
}
