<app-navbar></app-navbar>
<div id="goaie-home">
  <app-home-main-slider
    [landingSliders]="landingSliders"
  ></app-home-main-slider>
  <app-home-second-slider
    [goals]="goals"
    [contributionWord]="page.contributionWord"
    [contributionImage]="page.contributionImage"
  ></app-home-second-slider>
  <app-home-reports [reports]="reports"></app-home-reports>
  <app-home-countries
    [gulfCountries]="gulfCountries"
    [worldCountries]="worldCountries"
  ></app-home-countries>
  <app-home-articles [articles]="articles"> </app-home-articles>
  <app-home-certificate [page]="page"></app-home-certificate>
  <app-home-tools
    [tools]="tools"
    [applications]="applications"
  ></app-home-tools>
  <app-home-open-education
    [openEducationPlatforms]="openEducationPlatforms"
  ></app-home-open-education>
  <!-- <app-home-committee [workTeamMembers]="workTeamMembers"></app-home-committee> -->
  <app-home-camp *ngIf="camps.length > 0" [camps]="camps"></app-home-camp>
  <app-home-hackathon
    *ngIf="hackathons.length > 0"
    [hackathons]="hackathons"
  ></app-home-hackathon>
  <app-home-projects [studentProjects]="studentProjects"></app-home-projects>
</div>
<app-footer-links></app-footer-links>
