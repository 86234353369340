import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { WorldCountriesPageData } from "../interfaces/world-countries-page";
import { GET_WORLD_COUNTRIES_PAGE } from "../queries/world-countries-page.query";

@Injectable({
  providedIn: "root",
})
export class WorldCountriesPageResolver
  implements Resolve<WorldCountriesPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(): Observable<WorldCountriesPageData> {
    return this.apollo
      .query({
        query: GET_WORLD_COUNTRIES_PAGE,
      })
      .pipe(
        map((result): WorldCountriesPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "أفضل ممارسات وتجارب الدول العالمية",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            breadcrumbs: [{ title: meta.title }],
            data: page,
            meta,
          };
        })
      );
  }
}
