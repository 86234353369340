import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  Application,
  Article,
  Camp,
  GulfCountry,
  Hackathon,
  LandingContributionGoal,
  LandingPage,
  LandingPageData,
  LandingSlider,
  OpenEducationPlatform,
  Report,
  StudentProject,
  Tool,
  WorldCountry,
  workTeamMember,
} from "src/app/interfaces/landing-page";
import AOS from "aos";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  page!: LandingPage;
  goals!: LandingContributionGoal[];
  landingSliders!: LandingSlider[];
  reports!: Report[];
  articles!: Article[];
  gulfCountries!: GulfCountry[];
  worldCountries!: WorldCountry[];
  tools!: Tool[];
  applications!: Application[];
  openEducationPlatforms!: OpenEducationPlatform[];
  workTeamMembers!: workTeamMember[];
  camps!: Camp[];
  hackathons!: Hackathon[];
  studentProjects!: StudentProject[];

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as LandingPageData;
    this.page = page.meta;
    this.goals = page.data.goals;
    this.landingSliders = page.data.landingSliders;
    this.reports = page.data.reports;
    this.articles = page.data.articles;
    this.gulfCountries = page.data.gulfCountries;
    this.worldCountries = page.data.worldCountries;
    this.tools = page.data.tools;
    this.applications = page.data.applications;
    this.openEducationPlatforms = page.data.openEducationPlatforms;
    this.workTeamMembers = page.data.workTeamMembers;
    this.camps = page.data.camps;
    this.hackathons = page.data.hackathons;
    this.studentProjects = page.data.studentProjects;

    AOS.init({
      // disable: "mobile"
      once: true,
    });
    AOS.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
  }
}
