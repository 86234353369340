<app-page>
  <page-header></page-header>
  <page-content>
    <div id="page-about" class="py-5">
      <div class="container" style="min-height: 450px;">
        <div class="row">
          <div class="col-md-5 mt-4 ps-md-6">
            <div class="about-img-wrapper">
              <img
                [src]="aboutUsPage.aboutImage | imageLink"
                class="img-fluid"
                [alt]="aboutUsPage.aboutImage.title"
              />
            </div>
          </div>
          <div class="col mt-4">
            <h5 class="fw-bold text-primary mb-3">الرؤية</h5>
            <p [innerHTML]="aboutUsPage.vision"></p>
            <h5 class="fw-bold text-primary mt-4 mb-3">الرسالة</h5>
            <p [innerHTML]="aboutUsPage.mission"></p>
            <h5 class="fw-bold text-primary mt-4 mb-3">الأهداف</h5>
            <p [innerHTML]="aboutUsPage.goals"></p>
          </div>
        </div>

        <!-- <section class="my-6">
          <h3 class="text-center fw-bold mx-auto mb-5">
            <span class="d-inline-block mx-auto bg-pattern bg-gray"
              >منسقي الدول الأعضاء بالمكتب لعامي 2022 -2023</span
            >
          </h3>

          <div class="committe d-flex flex-wrap gap-4 justify-content-center">
            <div
              *ngFor="let workTeamMember of workTeamMembers"
              class="committee-card card"
            >
              <div class="card-body text-center">
                <div class="image">
                  <img
                    [src]="workTeamMember.avatar | imageLink"
                    class="profile-image mb-3"
                    [alt]="workTeamMember.avatar.title"
                    style="object-fit: unset"
                  />
                  <img
                    [src]="workTeamMember.country.image | imageLink"
                    class="flag"
                    [alt]="workTeamMember.country.image.title"
                  />
                </div>
                <h6 class="fw-bold text-dark mb-0">
                  {{ workTeamMember.name }}
                </h6>
                <h6 class="fw-bold text-dark small mt-2">
                  {{ workTeamMember.country.name }}
                </h6>
              </div>
            </div>
          </div>
        </section> -->
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
