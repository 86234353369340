import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Output,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import {DateAdapter} from "@angular/material/core";
import {MatCalendar, MatCalendarView} from "@angular/material/datepicker";
import {arEG, enUS} from "date-fns/locale";
import {ReplaySubject, Subject, takeUntil} from "rxjs";
import {Direction} from "src/app/enums/direction";
import {Locale} from "src/app/enums/locale";
import {LocalizationService} from "src/app/services/localization.service";
import {PeriodFilter} from "src/app/enums/period-filter";

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() currentView: MatCalendarView = "multi-year";
  @Output() selected!: Date | null;
  @Output() selectedChange = new EventEmitter<{date: Date; type: PeriodFilter}>();

  @ViewChild("calendar") calendar!: MatCalendar<Date>;

  direction: Direction = Direction.RIGHT_TO_LEFT;
  stateChanges = new Subject<void>();
  startView!: MatCalendarView;

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private localizationService: LocalizationService,
    private dateAdapter: DateAdapter<Date>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.startView = this.currentView;
    this.localizationService
      .getLocale()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locale) => {
        this.direction = locale.direction;
        if (locale.code === Locale.ARABIC) {
          this.dateAdapter.setLocale(arEG);
        } else if (locale.code === Locale.ENGLISH) {
          this.dateAdapter.setLocale(enUS);
        }
        this.stateChanges.next();
      });
  }

  ngAfterViewInit(): void {
    this.stateChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this.calendar) {
        this.calendar.stateChanges.next();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["currentView"]?.currentValue &&
      changes["currentView"].currentValue !== changes["currentView"].previousValue &&
      this.calendar
    ) {
      this.calendar.currentView = this.currentView;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  onSelectedChange(date: Date) {
    this.calendar.currentView = "month";
    this.selectedChange.emit({date, type: PeriodFilter.DAY});
    this.cdr.detach();
  }

  onMonthSelected(date: Date) {
    this.calendar.currentView = "year";
    this.selectedChange.emit({date, type: PeriodFilter.MONTH});
    this.cdr.detach();
  }

  onYearSelected(date: Date) {
    this.calendar.currentView = "multi-year";
    this.selectedChange.emit({date, type: PeriodFilter.YEAR});
    this.cdr.detach();
  }

  viewChangedHandler() {
    this.calendar.currentView = this.currentView;
    this.cdr.reattach();
  }
}
