import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReplaySubject, takeUntil } from "rxjs";
import { Paging, Report } from "src/app/interfaces/landing-page";
import {
  ReportsPage,
  ReportsPageData,
} from "src/app/interfaces/reports-page-page";
import { CMSService } from "src/app/services/cms.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-reports-page",
  templateUrl: "./reports-page.component.html",
  styleUrls: ["./reports-page.component.scss"],
})
export class ReportsPageComponent implements OnInit {
  constructor(private route: ActivatedRoute, private cmsService: CMSService) {}
  reports: Report[] = [];
  reportsPage!: ReportsPage;
  currentPage = 1;
  totalPages!: number;

  unsubscribe$ = new ReplaySubject(1);

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as ReportsPageData;
    this.reportsPage = page.meta;
    this.listReportsPaging();
    this.search();
  }

  onPageChangeNext(): void {
    this.reports = [];
    this.currentPage++;
    if (this.currentPage === 1) this.currentPage = 2;
    this.search();
  }

  onPageChangePrev(): void {
    this.reports = [];
    this.currentPage--;
    this.search();
  }

  search() {
    this.cmsService
      .listReports(this.currentPage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.reports = result;
          window.scrollTo(0, 0);
        },
        error: () => {},
      });
  }

  listReportsPaging() {
    this.cmsService
      .listReportsPaging()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (paging: Paging[]) => {
          this.totalPages = Math.ceil(
            Number(paging[0].count.id) / environment.limit.reportsLimit
          );
        },
        error: () => {},
      });
  }
}
