import { Component, Input, OnInit } from "@angular/core";
import { Hero } from "src/app/interfaces/hero";
import { ImageFile } from "src/app/interfaces/image-file";
import { ImageLinkPipe } from "src/app/pipes/image-link.pipe";

@Component({
  selector: "app-hero",
  templateUrl: "./hero.component.html",
  styleUrls: ["./hero.component.scss"],
})
export class HeroComponent implements OnInit {
  @Input() data!: Hero;
  @Input() pageTitle!: string;
  // @Input() heroImage!: ImageFile;
  // pageTitle!: string;
  constructor(private imageLinkPipe: ImageLinkPipe) {}
  ngOnInit(): void {
    this.pageTitle = this.data.title;
  }
  get imageURL() {
    if (!this.data) {
      return "";
    }
    return this.imageLinkPipe.transform(this.data.image);
  }
  // imageURL = "assets/images/hero-about-us.png";
}
