<app-page>
  <page-header></page-header>
  <page-content>
    <div id="page-articles" class="py-5">
      <div class="container">
        <div class="row">
          <div class="filters d-flex flex-wrap gap-2 mb-5">
            <div *ngFor="let category of studentProjectsCategories">
              <input
                type="radio"
                class="btn-check"
                name="category"
                id="btn-check-{{ category.nameEn }}"
                [checked]="category.id === currentCategories[0]"
                value="{{ category.id }}"
                (change)="selectCategoryTab($event)"
              />
              <label
                class="btn btn-very-light-gray rounded-1 fw-normal border-0"
                for="btn-check-{{ category.nameEn }}"
                >{{ category.nameAr }}</label
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div
            *ngFor="let studentProject of studentProjects"
            class="col-lg-3 col-md-4 col-sm-6"
          >
            <a routerLink="/students-projects/{{ studentProject.id }}">
              <div class="article-card px-3 mb-4 mb-md-5">
                <img
                  class="rounded-4 d-block img-fluid"
                  [src]="studentProject.image | imageLink"
                  [alt]="studentProject.image.title"
                />
                <h6 class="text-center text-dark fw-bold mt-3">
                  {{ studentProject.title }}
                </h6>
              </div>
            </a>
          </div>
        </div>

        <nav
          aria-label="Page navigation"
          class="mt-4 pagination justify-content-center"
        >
          <ul class="pagination">
            <li
              class="page-item text-center"
              [ngClass]="{ disabled: currentPage === 1 }"
            >
              <a
                class="page-link cursor-pointer"
                style="width: 100px; font-size: large; text-align: center"
                (click)="onPageChangePrev()"
                >السابق</a
              >
            </li>
            <li class="page-item text-center">
              <a class="page-link active">{{ currentPage }}</a>
            </li>
            <li
              class="page-item text-center"
              [ngClass]="{ disabled: currentPage >= totalPages }"
            >
              <a
                class="page-link cursor-pointer"
                style="width: 100px; font-size: large; text-align: center"
                (click)="onPageChangeNext()"
                >التالى</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
