import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { ArticlesPageData } from "../interfaces/articles-page-page";
import { GET_ARTICLES_PAGE } from "../queries/articles-page.query";

@Injectable({
  providedIn: "root",
})
export class ArticlesPageResolver implements Resolve<ArticlesPageData> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<ArticlesPageData> {
    return this.apollo
      .query({
        query: GET_ARTICLES_PAGE,
      })
      .pipe(
        map((result): ArticlesPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "مقالات علمية",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            breadcrumbs: [{ title: meta.title }],
            data: page,
            meta,
          };
        })
      );
  }
}
