import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Camp } from "src/app/interfaces/landing-page";
import { SingleCampPageData } from "src/app/interfaces/single-camp-page";

@Component({
  selector: "app-camps-single-page",
  templateUrl: "./camps-single-page.component.html",
  styleUrls: ["./camps-single-page.component.scss"],
})
export class CampsSinglePageComponent implements OnInit {
  camp!: Camp;

  camps!: Camp[];
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const { meta, ...page } = this.route.snapshot.data[
      "page"
    ] as SingleCampPageData;
    this.camp = page.data.camp;
    this.camps = page.data.camps;
  }
}
