<section id="goaie-reports" style="background-color: #fefbf5">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <div class="title mb-3 mt-5">تقارير وإصدارات</div>
        <div class="subtitle">
          يوفر المكتب عدداً كبيراً من التقارير والإصدارات المتعلقة بالذكاء الاصطناعي
        </div>
        <a routerLink="/reports" class="btn btn-outline-dark rounded-pill mb-5"
          >كافة التقارير والإصدارات</a
        >
      </div>
      <div class="col">
        <div flickity [flickityConfig]="flickityConfig" class="slider px-md-3">
          <div *ngFor="let report of reports" class="single-slide">
            <a routerLink="/reports/{{ report.id }}">
              <div class="report-card px-3 px-md-2 mb-3 mb-md-5">
                <img
                  class="rounded-4 d-block img-fluid mx-auto"
                  [src]="report.image | imageLink"
                  [alt]="report.image.title"
                />
                <h6 class="text-center text-dark fw-bold mt-3">
                  {{ report.title }}
                </h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
