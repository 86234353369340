import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Article } from "src/app/interfaces/landing-page";
import { SingleArticlePageData } from "src/app/interfaces/single-article-page";

@Component({
  selector: "app-articles-single-page",
  templateUrl: "./articles-single-page.component.html",
  styleUrls: ["./articles-single-page.component.scss"],
})
export class ArticlesSinglePageComponent implements OnInit {
  articles!: Article[];
  article!: Article;
  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const { meta, ...page } = this.route.snapshot.data[
        "page"
      ] as SingleArticlePageData;

      this.article = page.data.article;
      this.articles = page.data.articles;
    });
  }
}
