import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { StudentProject } from "src/app/interfaces/landing-page";
import { SingleStudentProjectPageData } from "src/app/interfaces/single-student-project-page";

@Component({
  selector: "app-student-project-single-page",
  templateUrl: "./student-project-single-page.component.html",
  styleUrls: ["./student-project-single-page.component.scss"],
})
export class StudentProjectSinglePageComponent implements OnInit {
  studentProjects!: StudentProject[];
  studentProject!: StudentProject;
  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const { meta, ...page } = this.route.snapshot.data[
        "page"
      ] as SingleStudentProjectPageData;

      this.studentProject = page.data.studentProject;
      this.studentProjects = page.data.studentProjects;
    });
  }
}
