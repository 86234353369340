import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LandingPageComponent } from "./components/pages/landing-page/landing-page.component";
import { LandingPageResolver } from "./resolvers/landing-page.resolver";
import { AboutPageComponent } from "./components/pages/about-page/about-page.component";
import { AiInfoPageComponent } from "./components/pages/ai-info-page/ai-info-page.component";
import { GulfCountriesComponent } from "./components/pages/gulf-countries/gulf-countries.component";
import { CountryComponent } from "./components/pages/country/country.component";
import { WorldCountriesComponent } from "./components/pages/world-countries/world-countries.component";
import { HackathonsSinglePageComponent } from "./components/pages/hackathons-single-page/hackathons-single-page.component";
import { ArticlesPageComponent } from "./components/pages/articles-page/articles-page.component";
import { ArticlesSinglePageComponent } from "./components/pages/articles-single-page/articles-single-page.component";
import { ToolsPageComponent } from "./components/pages/tools-page/tools-page.component";
import { ApplicationsPageComponent } from "./components/pages/applications-page/applications-page.component";
import { ReportsPageComponent } from "./components/pages/reports-page/reports-page.component";
import { ReportsSinglePageComponent } from "./components/pages/reports-single-page/reports-single-page.component";
import { AboutUsPageResolver } from "./resolvers/about-us-page.resolver";
import { AIPageResolver } from "./resolvers/ai-page.resolver";
import { GulfCountriesPageResolver } from "./resolvers/gulf-countries-page.resolver";
import { WorldCountriesPageResolver } from "./resolvers/world-countries-page.resolver";
import { SingleHackathonPageResolver } from "./resolvers/single-hackathon-page.resolver";
import { ArticlesPageResolver } from "./resolvers/articles-page.resolver";
import { SingleArticlePageResolver } from "./resolvers/single-article-page.resolver";
import { ReportsPageResolver } from "./resolvers/reports-page.resolver";
import { SingleReportPageResolver } from "./resolvers/single-report-page.resolver";
import { ToolsPageResolver } from "./resolvers/tools-page.resolver";
import { AppsPageResolver } from "./resolvers/apps-page.resolver";
import { ExperimentsPageResolver } from "./resolvers/experiments-page.resolver";
import { OpenEducationPlatformsPageComponent } from "./components/pages/open-education-platforms-page/open-education-platforms-page.component";
import { OpenEducationPlatformsPageResolver } from "./resolvers/open-education-platforms-page.resolver";
import { OpenEducationPlatformsSinglePageComponent } from "./components/pages/open-education-platforms-single-page/open-education-platforms-single-page.component";
import { SingleOpenEducationPlatformsPageResolver } from "./resolvers/single-open-education-platforms-page.resolver";
import { CampsPageComponent } from "./components/pages/camps-page/camps-page.component";
import { CampsPageResolver } from "./resolvers/camps-page.resolver";
import { CampsSinglePageComponent } from "./components/pages/camps-single-page/camps-single-page.component";
import { SingleCampsPageResolver } from "./resolvers/single-camps-page.resolver";
import { StudentsProjectsPageComponent } from "./components/pages/students-projects-page/students-projects-page.component";
import { StudentsProjectsPageResolver } from "./resolvers/students-projects-page.resolver";
import { StudentProjectSinglePageComponent } from "./components/pages/student-project-single-page/student-project-single-page.component";
import { SingleStudentProjectPageResolver } from "./resolvers/single-student-project-page.resolver";
import { HackathonsPageComponent } from "./components/pages/hackathons-page/hackathons-page.component";
import { HackathonsPageResolver } from "./resolvers/hackathons-page.resolver";
import { ToolsSinglePageComponent } from "./components/pages/tools-single-page/tools-single-page.component";
import { SingleToolPageResolver } from "./resolvers/single-tool-page.resolver";
import { SingleAppPageResolver } from "./resolvers/single-app-page.resolver";
import { AppsSinglePageComponent } from "./components/pages/apps-single-page/apps-single-page.component";
import { WorldCountryComponent } from "./components/pages/world-country/world-country.component";
import { WorldExperimentsPageResolver } from "./resolvers/world-experiments-page.resolver";

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    resolve: { page: LandingPageResolver },
  },
  {
    path: "about",
    component: AboutPageComponent,
    resolve: { page: AboutUsPageResolver },
  },
  {
    path: "ai",
    component: AiInfoPageComponent,
    resolve: { page: AIPageResolver },
  },
  {
    path: "gulf-countries",
    component: GulfCountriesComponent,
    resolve: { page: GulfCountriesPageResolver },
  },
  {
    path: "country/:countryId",
    component: CountryComponent,
    resolve: { page: ExperimentsPageResolver },
  },
  {
    path: "world-country/:countryId",
    component: WorldCountryComponent,
    resolve: { page: WorldExperimentsPageResolver },
  },

  //experiments
  // { path: "country-experiments/:countryId", component: CountryComponent },

  {
    path: "world-countries",
    component: WorldCountriesComponent,
    resolve: { page: WorldCountriesPageResolver },
  },
  {
    path: "articles",
    component: ArticlesPageComponent,
    resolve: { page: ArticlesPageResolver },
  },
  {
    path: "articles/:articleId",
    component: ArticlesSinglePageComponent,
    resolve: { page: SingleArticlePageResolver },
  },
  {
    path: "tools",
    component: ToolsPageComponent,
    resolve: { page: ToolsPageResolver },
  },

  {
    path: "tools/:toolId",
    component: ToolsSinglePageComponent,
    resolve: { page: SingleToolPageResolver },
  },
  {
    path: "applications",
    component: ApplicationsPageComponent,
    resolve: { page: AppsPageResolver },
  },
  {
    path: "applications/:applicationId",
    component: AppsSinglePageComponent,
    resolve: { page: SingleAppPageResolver },
  },
  {
    path: "reports",
    component: ReportsPageComponent,
    resolve: { page: ReportsPageResolver },
  },
  {
    path: "reports/:reportId",
    component: ReportsSinglePageComponent,
    resolve: { page: SingleReportPageResolver },
  },

  {
    path: "open-education-platforms",
    component: OpenEducationPlatformsPageComponent,
    resolve: { page: OpenEducationPlatformsPageResolver },
  },
  {
    path: "open-education-platforms/:platformId",
    component: OpenEducationPlatformsSinglePageComponent,
    resolve: { page: SingleOpenEducationPlatformsPageResolver },
  },

  {
    path: "camps",
    component: CampsPageComponent,
    resolve: { page: CampsPageResolver },
  },
  {
    path: "camps/:campId",
    component: CampsSinglePageComponent,
    resolve: { page: SingleCampsPageResolver },
  },

  {
    path: "hackathons",
    component: HackathonsPageComponent,
    resolve: { page: HackathonsPageResolver },
  },

  {
    path: "hackathons/:hackathonId",
    component: HackathonsSinglePageComponent,
    resolve: { page: SingleHackathonPageResolver },
  },

  {
    path: "students-projects",
    component: StudentsProjectsPageComponent,
    resolve: { page: StudentsProjectsPageResolver },
  },
  {
    path: "students-projects/:studentProjectId",
    component: StudentProjectSinglePageComponent,
    resolve: { page: SingleStudentProjectPageResolver },
  },

  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
