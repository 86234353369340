<app-page>
  <page-header></page-header>
  <page-content>
    <div id="page-tools" class="">
      <div class="container mt-4">
        <div class="row">
          <div
            *ngFor="let tool of tools"
            class="border-bottom col-md-3 col-sm-3 mb-4 d-flex flex-column align-items-center cursor-pointer"
            routerLink="/tools/{{ tool.id }}"
          >
            <a>
              <div
                class="img-wrapper bg-light-gray bg-opacity-10 rounded-3 overflow-hidden p-3"
                style="width: 150px; height: 150px"
              >
                <img
                  class="w-100 h-100 contain filter-dark"
                  [src]="tool.image | imageLink"
                  [alt]="tool.image.title"
                />
              </div>
            </a>
            <h6 class="text-center text-dark fw-bold mt-3 line-clamp-2">
              {{ tool.name }}
            </h6>
          </div>
        </div>

        <div class="text-center mt-5">
          <nav
            aria-label="Page navigation"
            class="mt-4 pagination justify-content-center"
          >
            <ul class="pagination">
              <li
                class="page-item text-center"
                [ngClass]="{ disabled: currentPage === 1 }"
              >
                <a
                  class="page-link cursor-pointer"
                  style="width: 100px; font-size: large; text-align: center"
                  (click)="onPageChangePrev()"
                  >السابق</a
                >
              </li>
              <li class="page-item text-center">
                <a class="page-link active">{{ currentPage }}</a>
              </li>
              <li
                class="page-item text-center"
                [ngClass]="{ disabled: currentPage >= totalPages }"
              >
                <a
                  class="page-link cursor-pointer"
                  style="width: 100px; font-size: large; text-align: center"
                  (click)="onPageChangeNext()"
                  >التالى</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
