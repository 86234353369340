import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  GulfCountriesPage,
  GulfCountriesPageData,
} from "src/app/interfaces/gulf-countries-page";
import { GulfCountry } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-gulf-countries",
  templateUrl: "./gulf-countries.component.html",
  styleUrls: ["./gulf-countries.component.scss"],
})
export class GulfCountriesComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  page!: GulfCountriesPage;
  gulfCountries!: GulfCountry[];
  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as GulfCountriesPageData;
    this.page = page.meta;
    this.gulfCountries = page.data.gulfCountries;
  }
}
