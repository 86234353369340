import { Component, Input, OnInit } from "@angular/core";
import { Camp } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-home-camp",
  templateUrl: "./home-camp.component.html",
  styleUrls: ["./home-camp.component.scss"],
})
export class HomeCampComponent implements OnInit {
  @Input() camps!: Camp[];
  constructor() {}

  ngOnInit(): void {}
}
