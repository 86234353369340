import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReplaySubject, takeUntil } from "rxjs";

import { OpenEducationPlatform, Paging } from "src/app/interfaces/landing-page";
import {
  OpenEducationPlatformsPage,
  OpenEducationPlatformsPageData,
} from "src/app/interfaces/open-education-platforms-page-page";
import { CMSService } from "src/app/services/cms.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-open-education-platforms-page",
  templateUrl: "./open-education-platforms-page.component.html",
  styleUrls: ["./open-education-platforms-page.component.scss"],
})
export class OpenEducationPlatformsPageComponent implements OnInit {
  constructor(private route: ActivatedRoute, private cmsService: CMSService) {}
  openEducationPlatforms: OpenEducationPlatform[] = [];
  openEducationPlatformsPage!: OpenEducationPlatformsPage;
  currentPage = 1;
  totalPages!: number;

  unsubscribe$ = new ReplaySubject(1);

  ngOnInit(): void {
    const page = this.route.snapshot.data[
      "page"
    ] as OpenEducationPlatformsPageData;
    this.openEducationPlatformsPage = page.meta;
    this.listOpenEducationPlatformsPaging();
    this.search();
  }

  onPageChangeNext(): void {
    this.openEducationPlatforms = [];
    this.currentPage++;
    if (this.currentPage === 1) this.currentPage = 2;
    this.search();
  }

  onPageChangePrev(): void {
    this.openEducationPlatforms = [];
    this.currentPage--;
    this.search();
  }

  search() {
    this.cmsService
      .listOpenEducationPlatforms(this.currentPage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.openEducationPlatforms = result;
          window.scrollTo(0, 0);
        },
        error: () => {},
      });
  }


  listOpenEducationPlatformsPaging() {
    this.cmsService
      .listOpenEducationPlatformsPaging()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (paging: Paging[]) => {
          this.totalPages = Math.ceil(
            Number(paging[0].count.id) /
              environment.limit.openEducationPlatformsLimit
          );
        },
        error: () => {},
      });
  }
}
