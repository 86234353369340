import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { SingleHackathonPageData } from "../interfaces/single-hackathon-page";
import { GET_SINGLE_HACKATHON_PAGE } from "../queries/single-hackathon-page.query";

@Injectable({
  providedIn: "root",
})
export class SingleHackathonPageResolver
  implements Resolve<SingleHackathonPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot): Observable<SingleHackathonPageData> {
    return this.apollo
      .query({
        query: GET_SINGLE_HACKATHON_PAGE,
        variables: {
          hackathonId: route.params["hackathonId"],
        },
      })
      .pipe(
        map((result): SingleHackathonPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: meta.title,
            breadcrumbs: [
              { title: meta.title },
              { title: page.hackathon.title },
            ],
            data: page,
            meta,
            hero: {
              title: meta.title,
              image: meta.image,
            },
          };
        })
      );
  }
}
