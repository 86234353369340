import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { SingleReportPageData } from "../interfaces/single-report-page";
import { GET_SINGLE_REPORT_PAGE } from "../queries/single-report-query";

@Injectable({
  providedIn: "root",
})
export class SingleReportPageResolver implements Resolve<SingleReportPageData> {
  constructor(private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot): Observable<SingleReportPageData> {
    return this.apollo
      .query({
        query: GET_SINGLE_REPORT_PAGE,
        variables: {
          reportId: route.params["reportId"],
        },
      })
      .pipe(
        map((result): SingleReportPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: meta.title,
            breadcrumbs: [
              { title: meta.title, routerLink: "/reports" },
              { title: page.report.title },
            ],
            data: page,
            meta,
            hero: {
              title: meta.title,
              image: meta.image,
            },
          };
        })
      );
  }
}
