<page-header></page-header>

<div id="page-hackathon" class="">
  <div
    class="hero bg-cover py-6 py-md-10"
    style="background-image: url('{{ hackathon.image | imageLink }}')"
  >
    <h1 class="text h1 fw-bold d-block mx-auto text-center text-white">
      {{ hackathon.title }}
    </h1>
    <div class="text-center mt-5">
      <button
        class="btn btn-outline-light rounded-0 fs-5 py-3 px-4"
        style="cursor: default"
      >
        يبداء {{ hackathon.startDate | date }}
      </button>
      <button
        class="btn btn-outline-light rounded-0 fs-5 py-3 px-4"
        style="cursor: default"
      >
        ينتهي {{ hackathon.endDate | date }}
      </button>
    </div>

    <div *ngIf="hackathon.registerationURL && hackathon.registerationURL.length>1" class="text-center mt-5">
      <a
        [href]="hackathon.registerationURL"
        target="_blank"
        class="btn btn-outline-light rounded-0 fs-5 py-3 px-4"
        style="cursor: pointer"
      >
        التسجيل في الهاكاثون
      </a>
    </div>
  </div>
  <section class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="bg-primary text-center text-white py-3 px-3">
            <div class="row">
              <div class="col-4">
                <div
                  class="d-flex flex-column align-items-center justify-content-center"
                >
                  <i class="fa-solid fa-hourglass-start fs-3 mb-2"></i>
                  <div class="fw-bold fs-6">عدد الساعات</div>
                </div>
              </div>
              <div class="col" style="padding-top: 16px">
                <h4>{{ hackathon.totalHours }}</h4>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="bg-primary text-center text-white py-3 px-3">
            <div class="row">
              <div class="col-4">
                <div
                  class="d-flex flex-column align-items-center justify-content-center"
                >
                  <i class="fa-solid fa-hourglass-start fs-3 mb-2"></i>
                  <div class="fw-bold fs-6">الفئة المستهدفة</div>
                </div>
              </div>
              <div class="col">
                {{ hackathon.targetAudience }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="bg-gold bg-opacity-10 mt-4 d-flex flex-column flex-md-row fw-bold"
      >
        <div class="flex-grow-1 p-3">
          <div class="text-gold">التخصص</div>
          <div class="">
            {{ hackathon.specialization }}
          </div>
        </div>
        <div class="bg-primary text-white text-center px-4 py-3">
          عدد الدورات
          <br />
          <h4>{{ hackathon.coursesNumber }}</h4>
        </div>
      </div>

      <div
        class="bg-gold bg-opacity-10 mt-4 p-3 d-flex flex-column flex-md-row fw-bold flex-wrap justify-content-evenly"
      >
        <div
          *ngFor="let instructor of hackathon.instructors"
          class="hackathon-instructor px-4 py-3"
        >
          <img
            *ngIf="instructor.image"
            [src]="instructor.image | imageLink"
            style="width: 67px; height: 67px"
            class="rounded-circle d-block mx-auto mb-3"
            alt=""
          />

          <i
            *ngIf="!instructor.image"
            class="fa fa-user d-block mx-auto mb-3 text-center"
            aria-hidden="true"
          ></i>
          <div class="text-center fw-bold">
            <p *ngIf="instructor.gender === 'male'" class="small mb-0">
              المدرب
            </p>
            <p *ngIf="instructor.gender === 'female'" class="small mb-0">
              المدربة
            </p>
            {{ instructor.name }}
          </div>
        </div>
      </div>

      <div class="timeline mt-6">
        <ul>
          <li>
            <h5 class="fw-bold">نبذة عن الهاكثون</h5>
            <section class="row flex-row-reverse my-3">
              <div class="col-md-4">
                <img
                  [src]="hackathon.aboutHackathonImage | imageLink"
                  class="img-fluid rounded-1"
                  [alt]="hackathon.aboutHackathonImage.title"
                />
              </div>
              <div class="col">
                <p [innerHTML]="hackathon.summary"></p>
              </div>
            </section>
          </li>

          <li>
            <h5 class="fw-bold">وصف الهاكثون</h5>
            <section class="row flex-row-reverse my-3">
              <div class="col">
                <p [innerHTML]="hackathon.description"></p>
              </div>
            </section>
          </li>

          <li>
            <h5 class="fw-bold">الشروط</h5>
            <section class="row flex-row-reverse my-3">
              <div class="col">
                <p [innerHTML]="hackathon.conditions"></p>
              </div>
            </section>
          </li>
        </ul>
      </div>
    </div>
  </section>
</div>
<page-footer></page-footer>
