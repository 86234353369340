import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { HackathonsPageData } from "../interfaces/hackathons-page";
import { GET_HACKATHONS_PAGE } from "../queries/hackathons-page.query";

@Injectable({
  providedIn: "root",
})
export class HackathonsPageResolver implements Resolve<HackathonsPageData> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<HackathonsPageData> {
    return this.apollo
      .query({
        query: GET_HACKATHONS_PAGE,
      })
      .pipe(
        map((result): HackathonsPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "الهاكاثون",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            breadcrumbs: [{ title: meta.title }],
            data: page,
            meta,
          };
        })
      );
  }
}
