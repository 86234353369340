import { gql } from "apollo-angular";
import { Tool } from "../interfaces/landing-page";
import { SingleToolPage } from "../interfaces/single-tool-page";

export const GET_SINGLE_TOOL_PAGE = gql<
  { page: SingleToolPage; tool: Tool; tools: Tool[] },
  { toolId: string }
>`
  query ($toolId: ID!) {
    page: tools_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    tool: ai_tools_by_id(id: $toolId) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      developerCompanyName: developer_company_name
      description
      images {
        imageId: directus_files_id {
          id
        }
      }
    }
  }
`;
