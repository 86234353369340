import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgVideo from "lightgallery/plugins/video";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"],
})
export class GalleryComponent implements OnChanges {
  @Input() video = false;

  defaultSettings: object = {
    licenseKey: "123",
    thumbnail: true,
    counter: false,
    selector: ".item",
    plugins: [lgThumbnail],
    nextHtml: '<i class="fa-regular fa-circle-right"></i>',
    prevHtml: '<i class="fa-regular fa-circle-left"></i>',
  };

  settings: object = this.defaultSettings;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes["video"] && changes["video"].currentValue !== changes["video"].previousValue) {
      this.settings = {
        ...this.defaultSettings,
        ...(this.video
          ? {
              videojs: true,
              videojsOptions: {
                muted: true,
              },
              plugins: [lgThumbnail, lgVideo],
            }
          : {}),
      };
    }
  }
}
