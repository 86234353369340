<section
  id="goaie-certificate"
  style="background-image: url(assets/images/certificate-bg.png)"
>
  <div class="container">
    <div class="text-center">
      <div class="title text-white d-inline-block mb-3 mt-5">
        وثيقة الذكاء الاصطناعي في التعليم
      </div>
      <div
        class="subtitle text-white text-center mx-auto mt-2"
        style="max-width: 700px"
        [innerHTML]="page.AIDefinitionText"
      ></div>
      <a
        routerLink="ai"
        class="btn btn-light mt-4 px-4 py-2 rounded-pill"
        >تفاصيل الوثيقة</a
      >
    </div>
  </div>
</section>
