<div id="goaie-main-slider">
  <div flickity [flickityConfig]="flickityConfig" class="slider">
    <div
      *ngFor="let landingSlider of landingSliders"
      class="hero-slide"
      style="
        background-image: url('{{ landingSlider.image | imageLink }}');
      "
    >
      <div
        class="captions text-white d-flex flex-column align-items-start justify-content-center fw-bold w-100 h-100 p-5 p-md-8"
      >
        <img
          alt=""
          class="logo img-fluid mb-2 mb-md-3"
          src="assets/images/goaie-logo.svg"
        />
        <div class="text1 mb-2 mb-md-3">{{ landingSlider.title }}</div>
        <div class="text2 mb-2 mb-md-3">
          {{ landingSlider.subTitle }}
        </div>
        <div class="actions mb-md-7">
          <a
            routerLink="/about"
            class="btn btn-primary rounded-pill px-6 py-2 mt-4"
            >رؤيتنا وأهدافنا</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
