import { gql } from "apollo-angular";
import { Tool, Paging } from "../interfaces/landing-page";
import { ToolsPage } from "../interfaces/tools-page";

export const GET_TOOLS_PAGE = gql<
  {
    page: ToolsPage;
    tools: Tool[];
    paging: Paging[];
  },
  void
>`
  query {
    page: tools_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    tools: ai_tools(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 10
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      developerCompanyName: developer_company_name
    }

    paging: ai_tools_aggregated(filter: { status: { _eq: "published" } }) {
      count {
        id
      }
    }
  }
`;

export const LIST_TOOLS = gql<
  { tools: Tool[]; paging: Paging },
  {
    limit: number;
    page: number;
  }
>`
  query ($limit: Int!, $page: Int!) {
    tools: ai_tools(
      filter: {
        _and: [
          { status: { _eq: "published" } }
        ]
      }
      sort: ["-date_created"]
      limit: $limit
      page: $page
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      developerCompanyName: developer_company_name
    }

    paging: ai_tools_aggregated(
      filter: {
        _and: [
          { status: { _eq: "published" } }
        ]
      }
    ) {
      count {
        id
      }
    }
  }
`;

export const LIST_TOOLS_PAGING = gql<
  { paging: Paging[] },
void
>`
  query{
    paging: ai_tools_aggregated(
      filter: {
        _and: [
          { status: { _eq: "published" } }
        ]
      }
    ) {
      count {
        id
      }
    }
  }
`;
