import { gql } from "apollo-angular";
import { AboutUsPage } from "src/app/interfaces/about-us-page";
import { workTeamMember } from "../interfaces/landing-page";

export const GET_ABOUTUS_PAGE = gql<
  { page: AboutUsPage; workTeamMembers: workTeamMember[] },
  void
>`
  query {
    page: about_page {
      title
      vision
      mission
      goals
      image {
        id
        filename: filename_download
        title
      }
      aboutImage: about_image {
        id
        filename: filename_download
        title
      }
    }
    workTeamMembers: work_team(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
    ) {
      id
      name
      avatar {
        id
        filename: filename_download
        title
      }
      jobPosition: job_position
      country {
        id
        name
        image {
          id
          filename: filename_download
          title
        }
        order
      }
    }
  }
`;
