import { Component, Input, OnInit } from "@angular/core";
import { OpenEducationPlatform } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-home-open-education",
  templateUrl: "./home-open-education.component.html",
  styleUrls: ["./home-open-education.component.scss"],
})
export class HomeOpenEducationComponent implements OnInit {
  @Input() openEducationPlatforms!: OpenEducationPlatform[];
  constructor() {}

  ngOnInit(): void {}
}
