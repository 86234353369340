import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { OpenEducationPlatformsPageData } from "../interfaces/open-education-platforms-page";
import { GET_OPEN_EDUCATION_PLATFORMS_PAGE } from "../queries/open-education-platforms-page.query";

@Injectable({
  providedIn: "root",
})
export class OpenEducationPlatformsPageResolver
  implements Resolve<OpenEducationPlatformsPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(): Observable<OpenEducationPlatformsPageData> {
    return this.apollo
      .query({
        query: GET_OPEN_EDUCATION_PLATFORMS_PAGE,
      })
      .pipe(
        map((result): OpenEducationPlatformsPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "منصات التدريب المفتوحة",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            breadcrumbs: [{ title: meta.title }],
            data: page,
            meta,
          };
        })
      );
  }
}
