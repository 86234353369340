import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { ReplaySubject, takeUntil } from "rxjs";
import { Direction } from "src/app/enums/direction";
import { StudentProject } from "src/app/interfaces/landing-page";
import { LocalizationService } from "src/app/services/localization.service";

@Component({
  selector: "app-home-projects",
  templateUrl: "./home-projects.component.html",
  styleUrls: ["./home-projects.component.scss"],
})
export class HomeProjectsComponent implements OnInit, OnDestroy {
  @Input() studentProjects!: StudentProject[];

  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    contain: true,
    groupCells: true,
    pageDots: false,
    prevNextButtons: true,
  };

  unsubscribe$ = new ReplaySubject(1);

  constructor(private localizationService: LocalizationService, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    this.localizationService
      .getLocale()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locale) => {
        this.flickityConfig.rightToLeft =
          locale.direction === Direction.RIGHT_TO_LEFT;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  cleanDescription(description: string) {
    const element = this.document.createElement("div");
    element.innerHTML = description.replace("فكرة المشروع :","فكرة المشروع:");
    element.querySelectorAll("p").forEach((p) => {
      const content = p.innerText.trim();
      if(content === "فكرة المشروع:") {
        p.remove();
      }
    });
    return element.innerText.replace("فكرة المشروع:", "").trim();
  }
}
