<app-page>
  <page-header></page-header>
  <page-content>
    <div id="page-articles-single" class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <img
              [src]="application.image | imageLink"
              class="img-fluid rounded-4 d-block mb-4"
              alt=""
            />
          </div>
          <div class="col">
            <h5 class="fw-bold">
              {{ application.name }}
            </h5>
            <p class="text-muted mb-4">
              {{ application.developerCompanyName }}
            </p>
            <p [innerHTML]="application.description"></p>
          </div>
        </div>
        <hr *ngIf="application.images.length > 0" class="mb-5" />
        <h5
          *ngIf="application.images.length > 0"
          class="fw-bold text-center my-4"
        >
          صور من الأداة
        </h5>
        <div class="row">
          <div
            *ngFor="let image of application.images; index as i"
            class="col-lg-3 col-md-4 col-sm-6"
          >
            <a>
              <div class="article-card px-3 mb-4 mb-md-5">
                <img
                  class="d-block w-100 cursor-pointer"
                  src="{{ assetsURI }}/{{ image.imageId.id }}"
                  [alt]="application.name"
                  style="height: 400px"
                  (click)="openZoomDialog(image.imageId.id, application.name)"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
