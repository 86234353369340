import { gql } from "apollo-angular";
import { AIPage } from "../interfaces/AI-page";

export const GET_AI_PAGE = gql<{ page: AIPage }, void>`
  query {
    page: AI_page {
      title
      image {
        id
        filename: filename_download
        title
      }
      introduction
      text1: text_1
      text2: text_2
      text3: text_3
      text4: text_4
      image1: image_1 {
        id
        filename: filename_download
        title
      }
      image2: image_2 {
        id
        filename: filename_download
        title
      }
      document {
        id
        filename: filename_download
        title
        type
      }
    }
  }
`;
