import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { WorldCountry } from "src/app/interfaces/landing-page";
import {
  WorldCountriesPage,
  WorldCountriesPageData,
} from "src/app/interfaces/world-countries-page";

@Component({
  selector: "app-world-countries",
  templateUrl: "./world-countries.component.html",
  styleUrls: ["./world-countries.component.scss"],
})
export class WorldCountriesComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  page!: WorldCountriesPage;
  worldCountries!: WorldCountry[];
  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as WorldCountriesPageData;
    this.page = page.meta;
    this.worldCountries = page.data.worldCountries;
  }
}
