<section id="goaie-open-education">
  <div class="container">
    <div class="text-center">
      <div class="title bg-pattern d-inline-block bg-pink mb-3 mt-5">
        منصات التدريب المفتوحة
      </div>
      <div class="subtitle text-center mx-auto mt-2">
        قسم متكامل لنشر منصات التعليم المختصة بتطوير الذكاء الاصطناعي
      </div>
    </div>

    <div class="row">
      <div
        *ngFor="let openEducationPlatform of openEducationPlatforms"
        class="col-lg-4 col-md-6 mb-3"
        style="height: 310px"
      >
        <a
          routerLink="/open-education-platforms/{{ openEducationPlatform.id }}"
          class="my-3 d-block h-100"
        >
          <div
            class="open-education-card card rounded-3 hover-shadow hover-border-primary h-100"
          >
            <div class="card-body">
              <div
                style="height: 85px"
                class="d-flex justify-content-center align-items-center"
              >
                <img
                  [src]="openEducationPlatform.image | imageLink"
                  style="max-height: 85px; height: initial"
                  [alt]="openEducationPlatform.image.title"
                />
              </div>
              <h6 class="text-center mt-3 mb-3 fw-bold fs-5">
                {{ openEducationPlatform.name }}
              </h6>
              <p
                [innerHTML]="openEducationPlatform.description"
                class="ellipse-4 text-dark"
              ></p>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="text-center mt-4">
      <a
        routerLink="/open-education-platforms"
        class="btn btn-outline-dark rounded-pill py-2 px-4"
        >المزيد من المنصات</a
      >
    </div>
  </div>
</section>
