import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { GET_SINGLE_TOOL_PAGE } from "../queries/single-tool-query";
import { SingleToolPageData } from "../interfaces/single-tool-page";

@Injectable({
  providedIn: "root",
})
export class SingleToolPageResolver implements Resolve<SingleToolPageData> {
  constructor(private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot): Observable<SingleToolPageData> {
    return this.apollo
      .query({
        query: GET_SINGLE_TOOL_PAGE,
        variables: {
          toolId: route.params["toolId"],
        },
      })
      .pipe(
        map((result): SingleToolPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: meta.title,
            breadcrumbs: [
              { title: meta.title, routerLink: "/tools" },
              { title: page.tool.name },
            ],
            data: page,
            meta,
            hero: {
              title: meta.title,
              image: meta.image,
            },
          };
        })
      );
  }
}
