import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-app-image-zoom",
  templateUrl: "./app-image-zoom.component.html",
  styleUrls: ["./app-image-zoom.component.scss"],
})
export class AppImageZoomComponent {
  constructor(
    public dialogRef: MatDialogRef<AppImageZoomComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  assetsURI = environment.assetsURI;

  closeDialog(): void {
    this.dialogRef.close();
  }
}
