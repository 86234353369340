import { gql } from "apollo-angular";
import { GulfCountry } from "../interfaces/landing-page";
import { GulfCountriesPage } from "../interfaces/gulf-countries-page";

export const GET_GULF_COUNTRIES_PAGE = gql<
  { page: GulfCountriesPage; gulfCountries: GulfCountry[] },
  void
>`
  query {
    page: gulf_countries_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }

    gulfCountries: gulf_countries(
      filter: { status: { _eq: "published" } }
      sort: ["order"]
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      order
    }
  }
`;
