import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { AIPageData } from "../interfaces/AI-page";
import { GET_AI_PAGE } from "../queries/AI-page.query";

@Injectable({
  providedIn: "root",
})
export class AIPageResolver implements Resolve<AIPageData> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<AIPageData> {
    return this.apollo
      .query({
        query: GET_AI_PAGE,
      })
      .pipe(
        map((result): AIPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "الذكاء الاصطناعي",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            breadcrumbs: [{ title: meta.title }],
            data: page,
            meta,
          };
        })
      );
  }
}
