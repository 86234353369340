import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { ExperimentsPageData } from "../interfaces/experiments-page";
import { GET_WORLD_EXPERIMENTS_PAGE } from "../queries/world-experiments-page.query";

@Injectable({
  providedIn: "root",
})
export class WorldExperimentsPageResolver
  implements Resolve<ExperimentsPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ExperimentsPageData> {
    return this.apollo
      .query({
        query: GET_WORLD_EXPERIMENTS_PAGE,
        variables: {
          countryId: route.params["countryId"],
        },
      })
      .pipe(
        map((result): ExperimentsPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: page.experiments[0]?.country.name || meta.title,
            hero: {
              title: page.experiments[0]?.country.name || meta.title,
              image: meta.image,
            },
            breadcrumbs: [
              { title: page.experiments[0]?.country.name || meta.title },
            ],
            data: page,
            meta,
          };
        })
      );
  }
}
