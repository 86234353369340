import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Hackathon } from "src/app/interfaces/landing-page";
import { SingleHackathonPageData } from "src/app/interfaces/single-hackathon-page";

@Component({
  selector: "app-hackathons-single-page",
  templateUrl: "./hackathons-single-page.component.html",
  styleUrls: ["./hackathons-single-page.component.scss"],
})
export class HackathonsSinglePageComponent implements OnInit {
  hackathon!: Hackathon;

  hackathons!: Hackathon[];
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const { meta, ...page } = this.route.snapshot.data[
      "page"
    ] as SingleHackathonPageData;
    this.hackathon = page.data.hackathon;
    this.hackathons = page.data.hackathons;
  }
}
