<app-page>
  <page-header></page-header>
  <page-content>
    <div id="page-single-country" class="py-3 pb-7">
      <div class="container" style="min-height: 420px;">
        <!-- <h6 class="fw-bold">{{experimentsPage.title}}</h6> -->
        <div *ngIf="experiments.length === 0" class="alert alert-info">
          لا توجد تجارب
        </div>
        <div class="accordion" id="country-accordion">
          <div
            *ngFor="let experiment of experiments"
            class="accordion-item my-3"
          >
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                [ngClass]="{ collapsed: experiment.id }"
                type="button"
                data-bs-toggle="collapse"
                attr.data-bs-target="#collapse{{ experiment.id }}"
              >
                <div class="actions ms-auto">
                  <a
                    *ngIf="experiment.youtubeUrl"
                    [href]="experiment.youtubeUrl"
                    target="_blank"
                    class="btn btn-link text-danger"
                    ><i class="fa-brands fa-youtube fs-4"></i
                  ></a>
                  <a
                    *ngIf="experiment.file"
                    [href]="experiment.file | fileLink"
                    target="_blank"
                    class="btn btn-link text-danger"
                    ><i class="fa-solid fa-file-pdf fs-4"></i
                  ></a>
                </div>
                {{ experiment.name }}
              </button>
            </h2>
            <div
              id="collapse{{ experiment.id }}"
              class="accordion-collapse collapse"
              [ngClass]="{ show: !experiment.id }"
              data-bs-parent="#country"
            >
              <div class="accordion-body">
                <div class="row">
                  <div class="col-md-5 mt-4" style="width: 30%">
                    <div class="about-img-wrapper">
                      <img
                        [src]="experiment.image | imageLink"
                        class="img-fluid"
                        [alt]="experiment.image.title"
                      />
                    </div>
                  </div>
                  <div class="col mt-4">
                    <p [innerHTML]="experiment.description"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
