import { gql } from "apollo-angular";
import { OpenEducationPlatform, Paging } from "../interfaces/landing-page";
import { OpenEducationPlatformsPage } from "../interfaces/open-education-platforms-page-page";

export const GET_OPEN_EDUCATION_PLATFORMS_PAGE = gql<
  {
    page: OpenEducationPlatformsPage;
    openEducationPlatforms: OpenEducationPlatform[];
    paging: Paging[];
  },
  void
>`
  query {
    page: open_platforms_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    openEducationPlatforms: open_education_platforms(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 10
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      description
    }

    paging: open_education_platforms_aggregated(
      filter: { status: { _eq: "published" } }
    ) {
      count {
        id
      }
    }
  }
`;

export const LIST_OPEN_EDUCATION_PLATFORMS = gql<
  { openEducationPlatforms: OpenEducationPlatform[]; paging: Paging },
  {
    limit: number;
    page: number;
  }
>`
  query ($limit: Int!, $page: Int!) {
    openEducationPlatforms: open_education_platforms(
      filter: { _and: [{ status: { _eq: "published" } }] }
      sort: ["-date_created"]
      limit: $limit
      page: $page
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      description
    }

    paging: open_education_platforms_aggregated(
      filter: { _and: [{ status: { _eq: "published" } }] }
    ) {
      count {
        id
      }
    }
  }
`;

export const LIST_OPEN_EDUCATION_PLATFORMS_PAGING = gql<
  { paging: Paging[] },
  void
>`
  query {
    paging: open_education_platforms_aggregated(
      filter: {
        _and: [
          { status: { _eq: "published" } }
        ]
      }
    ) {
      count {
        id
      }
    }
  }
`;
