import { environment } from "src/environments/environment";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import ar from "@angular/common/locales/ar";

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatDialogModule } from "@angular/material/dialog";

import {
  MatDateFnsModule,
  DateFnsAdapter,
} from "@angular/material-date-fns-adapter";

import { arEG } from "date-fns/locale";

import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache } from "@apollo/client/core";

import { LightgalleryModule } from "lightgallery/angular";

import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { AppRoutingModule } from "./app-routing.module";

import { FlickityDirective } from "./directives/flickity.directive";
import { SlickItemDirective } from "./directives/slick-item.directive";
import { CounterDirective } from "./directives/counter.directive";

import { ImageLinkPipe } from "./pipes/image-link.pipe";
import { FileLinkPipe } from "./pipes/file-link.pipe";
import { FileTypePipe } from "./pipes/file-type.pipe";

import { AppComponent } from "./app.component";
import { FooterLinksComponent } from "./components/shared/footer-links/footer-links.component";
import { FontsShareComponent } from "./components/shared/fonts-share/fonts-share.component";
import { SlickCarouselComponent } from "./components/shared/slick-carousel/slick-carousel.component";
import { LoaderComponent } from "./components/shared/loader/loader.component";
import { PageComponent } from "./components/shared/page/page.component";
import { PageContentComponent } from "./components/shared/page/page-content/page-content.component";
import { PageHeaderComponent } from "./components/shared/page/page-header/page-header.component";
import { PageFooterComponent } from "./components/shared/page/page-footer/page-footer.component";
import { NavbarComponent } from "./components/shared/navbar/navbar.component";
import { HeroComponent } from "./components/shared/hero/hero.component";
import { BreadcrumbComponent } from "./components/shared/breadcrumb/breadcrumb.component";
import { CalendarComponent } from "./components/shared/calendar/calendar.component";
import { GalleryComponent } from "./components/shared/gallery/gallery.component";

import { YouTubePlayerModule } from "@angular/youtube-player";
import { LandingPageComponent } from "./components/pages/landing-page/landing-page.component";
import { HomeMainSliderComponent } from "./components/pages/landing-page/home-main-slider/home-main-slider.component";
import { HomeSecondSliderComponent } from "./components/pages/landing-page/home-second-slider/home-second-slider.component";
import { HomeReportsComponent } from "./components/pages/landing-page/home-reports/home-reports.component";
import { HomeCountriesComponent } from "./components/pages/landing-page/home-countries/home-countries.component";
import { HomeArticlesComponent } from "./components/pages/landing-page/home-articles/home-articles.component";
import { HomeCertificateComponent } from "./components/pages/landing-page/home-certificate/home-certificate.component";
import { HomeToolsComponent } from "./components/pages/landing-page/home-tools/home-tools.component";
import { HomeOpenEducationComponent } from "./components/pages/landing-page/home-open-education/home-open-education.component";
import { HomeCommitteeComponent } from "./components/pages/landing-page/home-committee/home-committee.component";
import { HomeHackathonComponent } from "./components/pages/landing-page/home-hackathon/home-hackathon.component";
import { HomeProjectsComponent } from "./components/pages/landing-page/home-projects/home-projects.component";
import { AboutPageComponent } from "./components/pages/about-page/about-page.component";
import { AiInfoPageComponent } from "./components/pages/ai-info-page/ai-info-page.component";
import { GulfCountriesComponent } from "./components/pages/gulf-countries/gulf-countries.component";
import { CountryComponent } from "./components/pages/country/country.component";
import { WorldCountriesComponent } from "./components/pages/world-countries/world-countries.component";
import { HackathonsSinglePageComponent } from "./components/pages/hackathons-single-page/hackathons-single-page.component";
import { ArticlesPageComponent } from "./components/pages/articles-page/articles-page.component";
import { ArticlesSinglePageComponent } from "./components/pages/articles-single-page/articles-single-page.component";
import { ToolsPageComponent } from "./components/pages/tools-page/tools-page.component";
import { ApplicationsPageComponent } from "./components/pages/applications-page/applications-page.component";
import { ReportsPageComponent } from "./components/pages/reports-page/reports-page.component";
import { ReportsSinglePageComponent } from "./components/pages/reports-single-page/reports-single-page.component";
import { OpenEducationPlatformsPageComponent } from "./components/pages/open-education-platforms-page/open-education-platforms-page.component";
import { OpenEducationPlatformsSinglePageComponent } from "./components/pages/open-education-platforms-single-page/open-education-platforms-single-page.component";
import { CampsPageComponent } from "./components/pages/camps-page/camps-page.component";
import { CampsSinglePageComponent } from "./components/pages/camps-single-page/camps-single-page.component";
import { StudentsProjectsPageComponent } from "./components/pages/students-projects-page/students-projects-page.component";
import { StudentProjectSinglePageComponent } from "./components/pages/student-project-single-page/student-project-single-page.component";
import { HackathonsPageComponent } from "./components/pages/hackathons-page/hackathons-page.component";
import { HomeCampComponent } from "./components/pages/landing-page/home-camp/home-camp.component";
import { ToolsSinglePageComponent } from "./components/pages/tools-single-page/tools-single-page.component";
import { AppsSinglePageComponent } from "./components/pages/apps-single-page/apps-single-page.component";
import { AppImageZoomComponent } from "./components/shared/app-image-zoom/app-image-zoom.component";
import { WorldCountryComponent } from "./components/pages/world-country/world-country.component";

const angular = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
];

const material = [
  MatDatepickerModule,
  MatDateFnsModule,
  MatInputModule,
  MatFormFieldModule,
  MatIconModule,
  MatButtonModule,
  MatPaginatorModule,
  YouTubePlayerModule,
  MatDialogModule,
];

const thirdParties = [ApolloModule, LightgalleryModule, InfiniteScrollModule];

const directives = [FlickityDirective, SlickItemDirective, CounterDirective];

const pipes = [ImageLinkPipe, FileLinkPipe, FileTypePipe];

const components = [
  AppComponent,
  FooterLinksComponent,
  FontsShareComponent,
  SlickCarouselComponent,
  LoaderComponent,
  PageComponent,
  PageContentComponent,
  PageHeaderComponent,
  PageFooterComponent,
  NavbarComponent,
  HeroComponent,
  BreadcrumbComponent,
  CalendarComponent,
  GalleryComponent,
  LandingPageComponent,
];

registerLocaleData(ar);

@NgModule({
  declarations: [
    ...directives,
    ...pipes,
    ...components,
    HomeMainSliderComponent,
    HomeSecondSliderComponent,
    HomeReportsComponent,
    HomeCountriesComponent,
    HomeArticlesComponent,
    HomeCertificateComponent,
    HomeToolsComponent,
    HomeOpenEducationComponent,
    HomeCommitteeComponent,
    HomeHackathonComponent,
    HomeProjectsComponent,
    AboutPageComponent,
    AiInfoPageComponent,
    GulfCountriesComponent,
    CountryComponent,
    WorldCountriesComponent,
    HackathonsSinglePageComponent,
    ArticlesPageComponent,
    ArticlesSinglePageComponent,
    ToolsPageComponent,
    ApplicationsPageComponent,
    ReportsPageComponent,
    ReportsSinglePageComponent,
    OpenEducationPlatformsPageComponent,
    OpenEducationPlatformsSinglePageComponent,
    CampsPageComponent,
    CampsSinglePageComponent,
    StudentsProjectsPageComponent,
    StudentProjectSinglePageComponent,
    HackathonsPageComponent,
    HomeCampComponent,
    ToolsSinglePageComponent,
    AppsSinglePageComponent,
    AppImageZoomComponent,
    WorldCountryComponent,
  ],
  imports: [
    ...angular,
    ...material,
    ...thirdParties,
    AppRoutingModule,
  ],
  providers: [
    ...pipes,
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphqlURI,
          }),
        };
      },
      deps: [HttpLink],
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: arEG,
    },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ["l", "LL"],
        },
        display: {
          dateInput: "yyyy/MM/dd",
          monthYearLabel: "MMMM yyyy",
          monthLabel: "MMMM",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM yyyy",
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
