import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { CampsPageData } from "../interfaces/camps-page";
import { GET_CAMPS_PAGE } from "../queries/camps-page.query";

@Injectable({
  providedIn: "root",
})
export class CampsPageResolver implements Resolve<CampsPageData> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<CampsPageData> {
    return this.apollo
      .query({
        query: GET_CAMPS_PAGE,
      })
      .pipe(
        map((result): CampsPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "المعسكرات التدريبية",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            breadcrumbs: [{ title: meta.title }],
            data: page,
            meta,
          };
        })
      );
  }
}
