import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  Experiment,
  ExperimentsPage,
  ExperimentsPageData,
} from "src/app/interfaces/experiments-page";

@Component({
  selector: "app-country",
  templateUrl: "./country.component.html",
  styleUrls: ["./country.component.scss"],
})
export class CountryComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  experiments!: Experiment[];
  experimentsPage!: ExperimentsPage;
  ngOnInit(): void {
    const { meta, ...page } = this.route.snapshot.data[
      "page"
    ] as ExperimentsPageData;

    this.experimentsPage = meta;
    this.experiments = page.data.experiments;
  }
}
