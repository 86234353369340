<app-page>
  <page-header></page-header>
  <page-content>
    <div id="page-countries" class="py-3">
      <div class="container">
        <div class="countries d-flex flex-wrap gap-3 mt-5">
          <a
            *ngFor="let worldCountry of worldCountries"
            target="_self"
            routerLink="/world-country/{{ worldCountry.id }}"
          >
            <div class="country-card card">
              <div class="card-body text-center py-4">
                <img
                  [src]="worldCountry.image | imageLink"
                  class="country-img mb-3"
                  [alt]="worldCountry.image.title"
                />
                <h6 class="fw-bold text-dark">{{ worldCountry.name }}</h6>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
