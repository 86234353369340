import { Component, Input, OnInit } from "@angular/core";
import { GulfCountry, WorldCountry } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-home-countries",
  templateUrl: "./home-countries.component.html",
  styleUrls: ["./home-countries.component.scss"],
})
export class HomeCountriesComponent implements OnInit {
  @Input() gulfCountries!: GulfCountry[];
  @Input() worldCountries!: WorldCountry[];
  constructor() {}

  ngOnInit(): void {}
}
