import { gql } from "apollo-angular";
import { SingleArticlePage } from "../interfaces/single-article-page";
import { Article } from "../interfaces/landing-page";

export const GET_SINGLE_ARTICLE_PAGE = gql<
  { page: SingleArticlePage; article: Article; articles: Article[] },
  { articleId: string }
>`
  query ($articleId: ID!) {
    page: articles_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    article: articles_by_id(id: $articleId) {
      id
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      publisher
      articleUrl: article_url
      category {
        id
        nameAr
        nameEn
      }
      publishingDate: publishing_date
    }

    articles(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 4
    ) {
      id
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      publisher
      articleUrl: article_url
      category {
        id
        nameAr
        nameEn
      }
      publishingDate: publishing_date
    }
  }
`;
