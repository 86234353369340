import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { SingleStudentProjectPageData } from "../interfaces/single-student-project-page";
import { GET_SINGLE_STUDENT_PROJECT_PAGE } from "../queries/single-student-project-query";

@Injectable({
  providedIn: "root",
})
export class SingleStudentProjectPageResolver
  implements Resolve<SingleStudentProjectPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<SingleStudentProjectPageData> {
    return this.apollo
      .query({
        query: GET_SINGLE_STUDENT_PROJECT_PAGE,
        variables: {
          studentProjectId: route.params["studentProjectId"],
        },
      })
      .pipe(
        map((result): SingleStudentProjectPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: meta.title,
            breadcrumbs: [
              { title: meta.title, routerLink: "/students-projects" },
              { title: page.studentProject.title },
            ],
            data: page,
            meta,
            hero: {
              title: meta.title,
              image: meta.image,
            },
          };
        })
      );
  }
}
