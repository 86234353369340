import { gql } from "apollo-angular";
import { StudentProject } from "../interfaces/landing-page";
import { SingleStudentProjectPage } from "../interfaces/single-student-project-page";

export const GET_SINGLE_STUDENT_PROJECT_PAGE = gql<
  {
    page: SingleStudentProjectPage;
    studentProject: StudentProject;
    studentProjects: StudentProject[];
  },
  { studentProjectId: string }
>`
  query ($studentProjectId: ID!) {
    page: students_projects_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    studentProject: students_projects_by_id(id: $studentProjectId) {
      id
      title
      presenter
      image {
        id
        filename: filename_download
        title
      }
      date
      description
      document {
        id
        filename: filename_download
        title
        type
      }
    }

    studentProjects: students_projects(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 4
    ) {
      id
      title
      presenter
      image {
        id
        filename: filename_download
        title
      }
      date
      description
      document {
        id
        filename: filename_download
        title
        type
      }
    }
  }
`;
