import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject, takeUntil } from "rxjs";
import { Direction } from "src/app/enums/direction";
import { ImageFile } from "src/app/interfaces/image-file";
import { LandingContributionGoal } from "src/app/interfaces/landing-page";
import { LocalizationService } from "src/app/services/localization.service";

@Component({
  selector: "app-home-second-slider",
  templateUrl: "./home-second-slider.component.html",
  styleUrls: ["./home-second-slider.component.scss"],
})
export class HomeSecondSliderComponent implements OnInit {
  @Input() contributionWord!: string;
  @Input() contributionImage!: ImageFile;
  @Input() goals!: LandingContributionGoal[];
  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    contain: true,
    groupCells: true,
    pageDots: true,
    autoPlay: true,
  };

  unsubscribe$ = new ReplaySubject(1);

  constructor(private localizationService: LocalizationService) {}

  ngOnInit(): void {
    this.localizationService
      .getLocale()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locale) => {
        this.flickityConfig.rightToLeft =
          locale.direction === Direction.RIGHT_TO_LEFT;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}
