import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AboutUsPage, AboutUsPageData } from "src/app/interfaces/about-us-page";
import { workTeamMember } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-about-page",
  templateUrl: "./about-page.component.html",
  styleUrls: ["./about-page.component.scss"],
})
export class AboutPageComponent implements OnInit {
  aboutUsPage!: AboutUsPage;

  workTeamMembers!: workTeamMember[];
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as AboutUsPageData;    
    this.aboutUsPage = page.meta;
    this.workTeamMembers = page.data.workTeamMembers;
  }
}
