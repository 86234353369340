import { gql } from "apollo-angular";
import { Hackathon, Paging } from "../interfaces/landing-page";
import { HackathonsPage } from "../interfaces/hackathons-page";

export const GET_HACKATHONS_PAGE = gql<
  {
    page: HackathonsPage;
    hackathons: Hackathon[];
    paging: Paging[];
  },
  void
>`
  query {
    page: hackathons_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    hackathons(
      filter: { status: { _eq: "published" }, active: { _eq: true } }
      sort: ["-date_created"]
      limit: 10
    ) {
      id
      title
      startDate: start_date
      endDate: end_date
      image {
        id
        filename: filename_download
        title
      }
      aboutHackathonImage: about_hackathon_image {
        id
        filename: filename_download
        title
      }
      totalHours: total_hours
      instructors {
        id
        name
        image {
          id
          filename: filename_download
          title
        }
        gender
        hackathon: fk_instructor_hackathon {
          id
        }
      }
      targetAudience: target_audience
      coursesNumber: courses_number
      specialization
      summary
      description
      conditions
      active
    }
    paging: hackathons_aggregated(filter: { status: { _eq: "published" } }) {
      count {
        id
      }
    }
  }
`;

export const LIST_HACKATHONS = gql<
  { hackathons: Hackathon[]; paging: Paging },
  {
    limit: number;
    page: number;
  }
>`
  query ($limit: Int!, $page: Int!) {
    hackathons(
      filter: {
        _and: [{ status: { _eq: "published" }, active: { _eq: true } }]
      }
      sort: ["-date_created"]
      limit: $limit
      page: $page
    ) {
      id
      title
      startDate: start_date
      endDate: end_date
      image {
        id
        filename: filename_download
        title
      }
      aboutHackathonImage: about_hackathon_image {
        id
        filename: filename_download
        title
      }
      totalHours: total_hours
      instructors {
        id
        name
        image {
          id
          filename: filename_download
          title
        }
        gender
        hackathon: fk_instructor_hackathon {
          id
        }
      }
      targetAudience: target_audience
      coursesNumber: courses_number
      specialization
      summary
      description
      conditions
      active
    }

    paging: hackathons_aggregated(
      filter: { _and: [{ status: { _eq: "published" } }] }
    ) {
      count {
        id
      }
    }
  }
`;

export const LIST_HACKATHONS_PAGING = gql<{ paging: Paging[] }, void>`
  query {
    paging: hackathons_aggregated(
      filter: {
        _and: [{ status: { _eq: "published" }, active: { _eq: true } }]
      }
    ) {
      count {
        id
      }
    }
  }
`;
