import {
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";

import { ReplaySubject, takeUntil } from "rxjs";
import { ContactUsPage } from "src/app/interfaces/contact-us-page";
import { CMSService } from "src/app/services/cms.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-footer-links",
  templateUrl: "./footer-links.component.html",
  styleUrls: ["./footer-links.component.scss"],
})
export class FooterLinksComponent implements OnInit, OnDestroy {
  SCROLL_OFFSET = 300;

  // contact!: ContactUsPage;
  portalURI = environment.portalURI;
  @ViewChild("scrollTopBtn") scrollTopButtonRef!: ElementRef<HTMLButtonElement>;
  currentYear = new Date().getFullYear();
  unsubscribe$ = new ReplaySubject(1);
  footerLinks = [
    {
      title: "عن المرصد",
      href: "/about",
    },
    {
      title: " الذكاء الاصطناعي",
      href: "/ai",
    },
    {
      title: "المعسكر التدريبي",
      href: "/camps",
    },
    {
      title: "ماهو الذكاء الاصطناعي",
      href: "/ai",
    },
    {
      title: "ممارسات الدول الأعضاء",
      href: "/gulf-countries",
    },
    {
      title: "ممارسات الدول العالمية",
      href: "/world-countries",
    },
    {
      title: "المقالات العلمية",
      href: "/articles",
    },
    {
      title: "التقارير والإصدارات",
      href: "/reports",
    },
    
    {
      title: "منصات التدريب المفتوحة",
      href: "/open-education-platforms",
    },
    {
      title: "مشاريع الطلاب",
      href: "/students-projects",
    },
    {
      title: "الهاكاثون",
      href: "/hackathons",
    },
  
    {
      title: "تقارير",
      href: "/reports",
    },
  ];
  
  contact = {
    address: " المملكة العربية السعودية ،الرياض، طريق الملك سعود، الوزارات",
    phone: "966112664470+",
    email: "abegsai@abegs.org",
  };

  socialMediaLinks = [
    {
      title: "youtube",
      iconClass: "fa-brands fa-youtube",
      href: "https://www.youtube.com/@AbegsOrg",
    },
    {
      title: "instagram",
      iconClass: "fa-brands fa-instagram",
      href: "https://www.instagram.com/_abegsorg",
    },
    {
      title: "facebook",
      iconClass: "fa-brands fa-facebook",
      href: "https://www.facebook.com/abegsorg/",
    },
    {
      title: "twitter",
      iconClass: "fa-brands fa-twitter",
      href: "https://twitter.com/abegsorg",
    },
  ];
  constructor(private cmsService: CMSService) {}

  ngOnInit() {
    // this.fetchContactUsDetails();
  }

  @HostListener("window:scroll")
  onWindowScroll() {
    if (!this.scrollTopButtonRef) return;

    if (window.scrollY > this.SCROLL_OFFSET) {
      this.scrollTopButtonRef.nativeElement.classList.add("shown");
    } else {
      this.scrollTopButtonRef.nativeElement.classList.remove("shown");
    }
  }

  // fetchContactUsDetails() {
  //   this.cmsService
  //     .getContactUsDetails()
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe({
  //       next: (result) => {
  //         this.contact = result;
  //       },
  //     });
  // }
  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}
