<section id="goaie-projects" class="mb-5">
  <div class="container">
    <div class="text-center">
      <div class="title bg-pattern d-inline-block bg-green mb-3 mt-5">
        مشاريع الطلاب
      </div>
      <div class="subtitle text-center mx-auto mt-2">
        مشاركات ومساهمات الطلاب في تطوير الذكاء الاصطناعي وتطبيق ممارساته
      </div>
    </div>

    <div class="bg-opacity-10 rounded-3 p-4">
      <div flickity [flickityConfig]="flickityConfig" class="slider row">
        <div
          *ngFor="let studentProject of studentProjects"
          class="col-12 col-md-6 col-lg-4 px-3"
        >
          <div
            class="card border border-1 shadow-sm rounded-3 hover-shadow hover-border-primary cursor-pointer"
            routerLink="/students-projects/{{ studentProject.id }}"
          >
            <div class="d-flex justify-content-center">
              <div class="about-img-wrapper">
                <img
                  style="height: 250px"
                  [src]="studentProject.image | imageLink"
                  class="img-fluid border border-1"
                  [alt]="studentProject.image.title"
                />
              </div>
            </div>
            <div class="card-body">
              <h5 class="card-title fw-bold text-primary line-clamp" style="--lines-count: 2">
                {{ studentProject.title }}
              </h5>
              <p
                style="--lines-count: 5"
                class="card-text line-clamp"
                [innerHTML]="cleanDescription(studentProject.description)"
              ></p>
              <div class="d-flex justify-content-end">
                <a
                  routerLink="/students-projects/{{ studentProject.id }}"
                  class="btn btn-primary mt-3"
                  >تفاصيل المشروع</a
                >
              </div>
            </div>
          </div>
        </div>
        <!-- <div *ngFor="let studentProject of studentProjects" class="container-fluid">
          <div class="row">
            <div class="col-md-4 mt-4 d-flex justify-content-center">
              <div class="about-img-wrapper">
                <img
                  style="height: 250px"
                  [src]="studentProject.image | imageLink"
                  class="img-fluid cursor-pointer"
                  [alt]="studentProject.image.title"
                  routerLink="/students-projects/{{ studentProject.id }}"
                />
              </div>
            </div>
            <div class="col">
              <div
                class="h-100 d-flex flex-column justify-content-center align-items-start"
              >
                <h3 class="text-primary fw-bold">{{ studentProject.title }}</h3>
                <p class="my-2 fw-bold">{{ studentProject.presenter }}</p>
                <p class="my-2 fw-bold">{{ studentProject.date | date }}</p>
                <p
                  [innerHTML]="studentProject.description"
                  class="my-2 line-clamp-3"
                ></p>
                <div class="text-end mt-4 w-100 mb-3">
                  <a
                    routerLink="/students-projects/{{ studentProject.id }}"
                    class="btn btn-primary mx-2 ms-auto"
                    >تفاصيل المشروع</a
                  >
                  <a
                    [href]="studentProject.document | fileLink"
                    target="_blank"
                    class="btn btn-link mx-2"
                  >
                    <i
                      class="fa-solid fa-arrow-right-to-bracket fa-rotate-90"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</section>
