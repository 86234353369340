import { gql } from "apollo-angular";
import { Application } from "../interfaces/landing-page";
import { SingleApplicationPage } from "../interfaces/single-application-page";

export const GET_SINGLE_APP_PAGE = gql<
  { page: SingleApplicationPage; application: Application },
  { applicationId: string }
>`
  query ($applicationId: ID!) {
    page: apps_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    application: ai_apps_by_id(id: $applicationId) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      developerCompanyName: developer_company_name
      description
      images {
        imageId: directus_files_id {
          id
        }
      }
    }
  }
`;
