<section id="goaie-countries">
  <div class="container">
    <div class="text-center">
      <div class="title bg-pattern d-inline-block bg-green mb-3 mt-5">
        أفضل ممارسات وتجارب الدول
      </div>
      <div class="subtitle text-center mx-auto mt-2">
        نحرص علي مواكبة التغيُّرات العالمية في مجال الذكاء الاصطناعي
      </div>
    </div>

    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link me-4 rounded-pill active"
          id="pills-members-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-members"
          type="button"
          role="tab"
          aria-controls="pills-members"
          aria-selected="true"
        >
          الدول الأعضاء
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link me-4 rounded-pill"
          id="pills-experiences-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-experiences"
          type="button"
          role="tab"
          aria-controls="pills-experiences"
          aria-selected="false"
        >
          التجارب الدولية
        </button>
      </li>
    </ul>

    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-members"
        role="tabpanel"
        aria-labelledby="pills-members-tab"
        tabindex="0"
      >
        <div
          class="countries d-flex flex-wrap gap-2 mt-5 justify-content-center"
        >
          <a
            *ngFor="let gulfCountry of gulfCountries"
            target="_self"
            routerLink="/country/{{ gulfCountry.id }}"
          >
            <div class="country-card card">
              <div class="card-body text-center py-4">
                <img
                  [src]="gulfCountry.image | imageLink"
                  class="country-img mb-3"
                  [alt]="gulfCountry.image.title"
                />
                <h6 class="fw-bold text-dark">{{ gulfCountry.name }}</h6>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="pills-experiences"
        role="tabpanel"
        aria-labelledby="pills-experiences-tab"
        tabindex="0"
      >
        <div
          class="countries d-flex flex-wrap gap-3 mt-5 justify-content-center"
        >
          <a
            *ngFor="let worldCountry of worldCountries"
            target="_self"
            routerLink="/world-country/{{ worldCountry.id }}"
          >
            <div class="country-card card">
              <div class="card-body text-center py-4">
                <img
                  [src]="worldCountry.image | imageLink"
                  class="country-img mb-3"
                  [alt]="worldCountry.image.title"
                />
                <h6 class="fw-bold text-dark">{{ worldCountry.name }}</h6>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
