import { Component, Input, OnInit } from "@angular/core";
import { Hackathon } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-home-hackathon",
  templateUrl: "./home-hackathon.component.html",
  styleUrls: ["./home-hackathon.component.scss"],
})
export class HomeHackathonComponent implements OnInit {
  @Input() hackathons!: Hackathon[];
  constructor() {}

  ngOnInit(): void {}
}
