import { Component, Input, OnInit } from "@angular/core";
import { Application, Tool } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-home-tools",
  templateUrl: "./home-tools.component.html",
  styleUrls: ["./home-tools.component.scss"],
})
export class HomeToolsComponent implements OnInit {
  @Input() tools!: Tool[];
  @Input() applications!: Application[];

  constructor() {}

  ngOnInit(): void {}
}
