<section id="goaie-contribution">
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-8">
        <div class="title mb-5">
          {{ contributionWord }}
        </div>
        <div flickity [flickityConfig]="flickityConfig" class="slider px-xl-5">
          <div
            *ngFor="let goal of goals"
            class="single-slide px-5 w-100 h6 text-center py-5"
          >
            {{ goal.goal }}
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-4 d-flex justify-content-center py-5 py-xl-0">
        <img
          [src]="contributionImage | imageLink"
          class="img-fluid"
          [alt]="contributionImage.title"
        />
      </div>
    </div>
  </div>
</section>
