import { gql } from "apollo-angular";
import { SingleReportPage } from "../interfaces/single-report-page";
import { Report } from "../interfaces/landing-page";

export const GET_SINGLE_REPORT_PAGE = gql<
  { page: SingleReportPage; report: Report; reports: Report[] },
  { reportId: string }
>`
  query ($reportId: ID!) {
    page: reports_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    report: reports_by_id(id: $reportId) {
      id
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      publisher
      reportUrl: report_url
      publishingDate: publishing_date
    }

    reports(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 4
    ) {
      id
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      publisher
      reportUrl: report_url
      publishingDate: publishing_date
    }
  }
`;
